<!--线缆-->

<template>
    <el-form
        class="text item"
        :model="formData"
        :rules="formRules"
        ref="form"
        size="mini"
        :disabled="type === 'detail'"
    >
        <el-row :gutter="24">
            <el-col :span="24">
                <el-card class="box-card" style="margin: 0px 10px 10px 0px; width: 100%">
                    <div slot="header" class="clearfix">
                        <span>基本信息</span>
                    </div>
                    <div class="text item">
                        <el-row :gutter="24">
                            <el-col :span="6">
                                <el-form-item label="设备类别" prop="category">
                                    <el-select v-model="formData.category" clearable style="width: 100%">
                                        <el-option
                                            v-for="item in equipmentCategories"
                                            :key="item.value"
                                            :label="item.name"
                                            :value="item.value"
                                        >
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="名称" prop="name">
                                    <el-input v-model="formData.name"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="编号" prop="number">
                                    <el-input v-model="formData.number"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="资产性质" prop="natureOfPropertyRight">
                                    <el-select v-model="formData.natureOfPropertyRight" clearable style="width: 100%">
                                        <el-option
                                            v-for="item in natureOfPropertyRights"
                                            :key="item.value"
                                            :label="item.name"
                                            :value="item.value"
                                        >
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-row :gutter="24">
                            <el-col :span="6">
                                <el-form-item label="厂家/品牌" prop="manufacturer">
                                    <el-input v-model="formData.manufacturer"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="出厂编号" prop="manufacturingNumber">
                                    <el-input v-model="formData.manufacturingNumber"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="附属设备" prop="accessoryEquipment">
                                    <el-input v-model="formData.accessoryEquipment"></el-input>
                                </el-form-item>
                            </el-col>

                            <!--<el-col :span="6">
                                <el-form-item :label="'质保日期'" prop="qualityAssuranceDate">
                                    <el-date-picker
                                        v-model="formData.qualityAssuranceDate"
                                        type="date"
                                        style="width: 100%"
                                        :placeholder="'请选择'"
                                        value-format="yyyy-MM-dd"
                                    ></el-date-picker>
                                </el-form-item>
                            </el-col>-->
                            <el-col :span="6">
                                <el-form-item label="设备类型" prop="equipmentType">
<!--                                    <el-input v-model="formData.equipmentType"></el-input>-->
                                    <el-select v-model="formData.equipmentType" clearable style="width: 100%">
                                        <el-option
                                            v-for="item in equipmentTypes"
                                            :key="item.value"
                                            :label="item.name"
                                            :value="item.value"
                                        >
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
<!--                        //站内设备 Station equipment   配电线路设备 Distribution line equipment-->
                        <el-row :gutter="24">
                            <el-col :span="6">
                                <el-form-item :label="'出厂日期'" prop="productionDate">
                                    <el-date-picker
                                        v-model="formData.productionDate"
                                        type="date"
                                        style="width: 100%"
                                        :placeholder="'请选择'"
                                        value-format="yyyy-MM-dd"
                                    ></el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item :label="'投产日期'" prop="commissionDate">
                                    <el-date-picker
                                        v-model="formData.commissionDate"
                                        style="width: 100%"
                                        type="date"
                                        :placeholder="'请选择'"
                                        value-format="yyyy-MM-dd"
                                    ></el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="型号" prop="model">
                                    <el-input v-model="formData.model"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="额定电流（A）" prop="ratedCurrent">
                                    <el-input-number
                                        v-model="formData.ratedCurrent"
                                        :precision="2"
                                        :controls="false"
                                        style="width: 100%"
                                    ></el-input-number>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                               <!-- <el-form-item label="所属用户" prop="customerId">
                                    <el-select v-model="formData.customerId" clearable style="width: 100%">
                                        <el-option
                                            v-for="item in equipmentCustomers"
                                            :key="item.id"
                                            :label="item.customerName"
                                            :value="item.id"
                                        >
                                        </el-option>
                                    </el-select>
                                </el-form-item>-->
                            </el-col>
                        </el-row>
                    </div>
                </el-card>
            </el-col>
            <!--<el-col :span="8">
                <el-card class="box-card" style="margin: 0px 10px 10px 0px; width: 100%">
                    <div slot="header" class="clearfix">
                        <span>配网信息</span>
                    </div>
                    <div class="text item">
                        <el-row :gutter="24">
                            <el-col :span="12">
                                <el-form-item label="离地高度（米）" prop="terrainClearance">
                                    <el-input-number
                                        v-model="formData.terrainClearance"
                                        :precision="2"
                                        :controls="false"
                                        style="width: 100%"
                                    ></el-input-number>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="依附杆塔">
                                    <el-input
                                        v-model="formData.powerGridInfo.equipmentName"
                                        :disabled="true"
                                        readonly="readonly"
                                    ></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-row :gutter="24">
                            <el-col :span="12">
                                <el-form-item label="依附线路">
                                    <el-input
                                        v-model="formData.powerGridInfo.powerGridName"
                                        :disabled="true"
                                        readonly="readonly"
                                    ></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>
                </el-card>
            </el-col>-->
        </el-row>
        <el-row :gutter="24">
            <el-col :span="14">
                <el-card class="box-card" style="margin: 0px 10px 10px 0px; width: 100%">
                    <div slot="header" class="clearfix">
                        <span>配网信息</span>
                    </div>
                    <div class="text row">
                        <el-table
                            :data="_.isEmpty(formData.powerGridInfo) ? [] : [formData.powerGridInfo]"
                            style="width: 100%"
                        >
                           <!--<el-table-column prop="inputWireName" label="进线线路"> </el-table-column>
                            <el-table-column prop="joinInputEquipmentName" label="进线设备"> </el-table-column>-->
                            <el-table-column prop="joinInputEquipmentName" label="上级电源点">
                                <template slot-scope="item">
                                    <span v-if="item.row.joinInputBoxName != null && item.row.joinInputEquipmentName != null">
                                    {{item.row.joinInputEquipmentName}}<br/>
                                    {{item.row.joinInputBoxName}}
                                    </span>
                                    <span v-if="item.row.joinInputBoxName == null">
                                        {{item.row.joinInputEquipmentName}}
                                    </span>
                                </template>
                            </el-table-column>
                            <!--<el-table-column prop="equipmentName" label="通过设备"> </el-table-column>-->
                            <el-table-column prop="equipmentName" label="设备所在杆/塔"> </el-table-column>
                            <el-table-column prop="joinOutputEquipmentName" label="下级设备"> </el-table-column>
                            <!--<el-table-column prop="powerGridName" label="依附线路"> </el-table-column>-->
                            <el-table-column prop="formData.terrainClearance" label="离地高度(米)">
                                <template>
                                    <el-input-number style="width: 100%" v-model="formData.terrainClearance" :precision="2" :controls="false"/>
                                </template>
                            </el-table-column>
                            <!--<el-table-column prop="outputWireName" label="出线线路"> </el-table-column>
                            <el-table-column prop="joinOutputEquipmentName" label="出线设备"> </el-table-column>-->
                            <!--<el-table-column prop="address" label="线路状态"> </el-table-column>-->
                        </el-table>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="10">
                <el-card class="box-card" style="margin: 0px 10px 10px 0px; width: 100%">
                    <div slot="header" class="clearfix">
                        <span>设备照片</span>
                    </div>
                    <div class="text row">
                        <ex-uploader v-model="formData.files" :disabled="type === 'detail'"></ex-uploader>
                    </div>
                </el-card>
            </el-col>
        </el-row>
    </el-form>
</template>
<script>
export default {
    name: 'LeverSwitchForm',
    props: {
        id: {
            type: Number,
            default: null,
        },
        type: {
            type: String,
            default: 'add',
        },
    },
    data() {
        return {
            uploadHeaders: {
                token: this.$store.state.token,
            },
            uploadUrl: this.$client.uploadFileUrl,
            formData: {
                category: null,
                name: null,
                number: null,
                natureOfPropertyRight: null,
                manufacturer: null,
                manufacturingNumber: null,
                qualityAssuranceDate: null,
                accessoryEquipment: null,
                productionDate: null,
                commissionDate: null,
                equipmentType: null,
                model: null,
                ratedCurrent: null,
                customerId: null,
                terrainClearance: null,
                towerPoleId: null,
                cableId: null,
                powerGridInfo: {},
                files: [],
            },
            formRules: {
                category: [{required: true, message: '请选择设备类别', trigger: 'blur'}],
                name: [
                    {required: true, message: '请填写名称', trigger: 'blur'},
                    {min: 2, max: 40, message: '长度在 2 到 40 个字符', trigger: 'blur'},
                ],
                number: [
                    {required: true, message: '请选择设备编号', trigger: 'blur'},
                    {min: 2, max: 40, message: '长度在 2 到 40 个字符', trigger: 'blur'},
                ],
                natureOfPropertyRight: [{required: false, message: '请选择资产性质', trigger: 'blur'}],
                equipmentType: [{required: false, message: '请选择设备分类', trigger: 'blur'}],
                manufacturer: [
                    {required: false, message: '请选择厂家/品牌', trigger: 'blur'},
                    {min: 2, max: 40, message: '长度在 2 到 40 个字符', trigger: 'blur'},
                ],
                manufacturingNumber: [
                    {required: false, message: '请填写出厂编号', trigger: 'blur'},
                    {min: 2, max: 40, message: '长度在 2 到 40 个字符', trigger: 'blur'},
                ],
                qualityAssuranceDate: [{required: false, message: '请选择质保日期', trigger: 'blur'}],
                accessoryEquipment: [
                    {required: false, message: '附属设备', trigger: 'blur'},
                    {min: 1, max: 40, message: '长度在 2 到 30 个字符', trigger: 'blur'},
                ],
                productionDate: [{required: false, message: '请选择出厂日期', trigger: 'blur'}],
                commissionDate: [{required: true, message: '请选择投产日期', trigger: 'blur'}],
                model: [
                    {required: false, message: '请填写型号', trigger: 'blur'},
                    {min: 2, max: 40, message: '长度在 2 到 40 个字符', trigger: 'blur'},
                ],
                terrainClearance: [{required: false, message: '请选择离地高度', trigger: 'blur'}],
            },
            natureOfPropertyRights: [],
            equipmentCategories: [],
            equipmentTypes: [],
        };
    },
    methods: {
        uploadFileSucceed(res) {
            const {data} = res;
            this.formData.files.push(data);
        },
        //提交
        submitHandle(callback) {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    if (this.formData.id) {
                        this.$client.updateTowerPoleSwitch(this.formData.id, this.formData).then((ret) => {
                            if (ret.code === 0) {
                                this.$message({
                                    message: '操作成功！',
                                    type: 'success',
                                });
                                if (callback) {
                                    callback();
                                }
                                console.log(this.formData.natureOfPropertyRight + '意思');
                                console.log(this.natureOfPropertyRights);
                                console.log('------------------------------------');
                                console.log(this.formData.equipmentType + '难搞');
                                console.log(this.equipmentTypes);
                            } else {
                                this.$message({
                                    message: ret.msg,
                                    type: 'warning',
                                });
                            }
                        });
                    } else {
                        this.$client.insertTowerPoleSwitch(this.formData).then((ret) => {
                            if (ret.code === 0) {
                                this.$message({
                                    message: '操作成功！',
                                    type: 'success',
                                });
                                if (callback) {
                                    callback();
                                }
                            } else {
                                this.$message({
                                    message: ret.msg,
                                    type: 'warning',
                                });
                            }
                        });
                    }
                }
            });
        },
    },
    created () {
        const dictMap = [
            {listName: 'natureOfPropertyRights', parentCode: 'device_nature_of_property_right'},
            {listName: 'equipmentTypes', parentCode: 'equipment_classification'},
            {listName: 'equipmentCategories', parentCode: 'tower_pole_switch_equipment_category'},
        ];
        this.$client.listDictionaryChildrenArr(dictMap.map((it) => it.parentCode)).then((res) => {
            let {data: resData} = res;
            for (let key in resData) {
                let data = resData[key];
                let dict = dictMap.filter((it) => it.parentCode === key)[0];
                this[dict.listName] = data;
                console.log(key + '测试1');
                console.log(this[dict.listName] + '测试2');
            }
        });
        this.$client.listEquipmentCustomer().then((res) => {
            this.equipmentCustomers = res.data || {};
        });
        if (this.id) {
            this.$client.getTowerPoleSwitch(this.id).then((ret) => {
                if (ret.code === 0) {
                    let formData = ret.data;
                    //过滤设备所在杆/塔 字段去除编号
                    if (formData.powerGridInfo.equipmentName) {
                        let s = formData.powerGridInfo.equipmentName;
                        let i = s.indexOf('(');
                        let i1 = s.indexOf('-');
                        let str = s.slice(i1 + 1, i);
                        formData.powerGridInfo.equipmentName = str;
                    }
                    //过滤上级电源点字段 去除 xx- 编号
                    /* if (formData.powerGridInfo.joinInputEquipmentName) {
                        let s1 = formData.powerGridInfo.joinInputEquipmentName;
                        let i1 = s1.indexOf('站');
                        let i2 = s1.indexOf('(');
                        let i3 = s1.indexOf('-');
                        let str1 = s1.slice(i1 + 1, i2);
                        let str2 = str1.slice(i3 + 1);
                        formData.powerGridInfo.joinInputEquipmentName = str2;
                    }*/
                    //过滤下级设备 去除编号 前缀
                    if (formData.powerGridInfo.joinOutputEquipmentName) {
                        let s1 = formData.powerGridInfo.joinOutputEquipmentName;
                        let i1 = s1.indexOf('-');
                        let i2 = s1.indexOf('(');
                        let str1 = s1.slice(i1 + 1, i2);
                        formData.powerGridInfo.joinOutputEquipmentName = str1;
                    }
                    //过滤柜体名称 去除中文
                    /*if (formData.powerGridInfo.joinInputBoxName) {
                        let s2 = formData.powerGridInfo.joinInputBoxName;
                        let reg = /[\u4E00-\u9FA5]/g;
                        let str2 = s2.replace(reg, '');
                        formData.powerGridInfo.joinInputBoxName = str2;
                    }*/
                    if (!formData.powerGridInfo) {
                        formData.powerGridInfo = {};
                    }
                    this.formData = formData;
                } else {
                    this.$message({
                        message: ret.msg,
                        type: 'warning',
                    });
                }
            });
        }
    },
};
</script>
<style lang="less" scoped>
.el-icon-circle-plus{
    cursor: pointer;
}
</style>
