<!--户外开关站-->
<template>
    <div>
        <el-form class="text row" :model="formData" :rules="formRules" ref="form" size="mini" :disabled="!editable">
            <el-row :gutter="24">
                <el-col :span="24">
                    <el-card class="box-card" style="margin: 0px 10px 10px 0px; width: 100%">
                        <div slot="header" class="clearfix">
                            <span>基本信息</span>
                        </div>
                        <div class="text row">
                            <el-row :gutter="24">
                                <ex-form-item
                                    v-for="(item, index) in baseItems"
                                    :key="index"
                                    :item="item"
                                    :formData="formData"
                                ></ex-form-item>
                            </el-row>
                        </div>
                    </el-card>
                </el-col>
            </el-row>

            <el-card class="box-card" style="margin: 0px 10px 10px 0px; width: 100%">
                <div slot="header" class="clearfix">
                    <span>柜体信息</span>
                </div>
                <div class="text row">
                    <ex-edit-table-form
                        ref="editTableForm"
                        :rules="boxRules"
                        size="mini"
                        :table-props="{
                            stripe: true,
                            border: true,
                            data: boxData,
                            size: 'mini',
                            height: '300px',
                        }"
                        :disabled="!editable"
                        table-style="width: 100%"
                        class="task-book-info"
                        :columns="boxColumns"
                        :rowSave="handleBoxSave"
                        :formItems="boxItems"
                    >
                        <template slot="append">
                            <el-table-column align="center" label="操作" type="action" width="100" fixed="right">
                                <template slot-scope="scope">
                                    <el-button
                                        :data-delindex="scope"
                                        @click="handleEditSwitchesContent(scope)"
                                        type="text"
                                        v-if="scope.row.id"
                                        >柜体开关维护</el-button
                                    >
                                    <el-button
                                        :data-delindex="scope"
                                        @click="handleEditBoxDelete(scope)"
                                        type="text"
                                        v-if="editable"
                                        >删除</el-button
                                    >
                                </template>
                            </el-table-column>
                        </template>
                    </ex-edit-table-form>
                    <div
                        class="ui-align--center ui-font--xxlarg"
                        style="font-size: 24px"
                        v-if="editable"
                    >
                        <i class="el-icon-circle-plus" title="添加柜体信息" @click="handleRowAddBox"></i>
                    </div>
                </div>
            </el-card>

            <el-row :gutter="24">
                <el-col :span="12">
                    <el-card class="box-card" style="margin: 0px 10px 10px 0px; width: 100%">
                        <div slot="header" class="clearfix">
                            <span>配网信息</span>
                        </div>
                        <div class="text row">
                            <ex-form-item
                                    v-for="(item, index) in netItems"
                                    :key="index"
                                    :item="item"
                                    :formData="formData"
                            ></ex-form-item>
                        </div>
                        <div class="text row">
                            <el-table :data="formData.powerGridInfo.data" style="width: 100%">
                                <el-table-column prop="boxName" label="设备"> </el-table-column>
                                <el-table-column prop="wireName" label="连接线缆"> </el-table-column>
                                <el-table-column prop="joinEquipmentName" label="连接设备"> </el-table-column>
                                <el-table-column prop="address" label="线路状态"> </el-table-column>
                            </el-table>
                        </div>
                    </el-card>
                </el-col>
                <el-col :span="12">
                    <el-card class="box-card" style="margin: 0px 10px 10px 0px; width: 100%">
                        <div slot="header" class="clearfix">
                            <span>设备照片</span>
                        </div>
                        <div class="text item">
                            <ex-uploader v-model="formData.files" :disabled="!editable"></ex-uploader>
                        </div>
                    </el-card>
                </el-col>
            </el-row>
        </el-form>
        <el-dialog
            width="80%"
            top="5vh"
            :title="dialog.title"
            v-if="dialog.visible"
            :visible.sync="dialog.visible"
            :modal-append-to-body="true"
            :close-on-click-modal="false"
            :append-to-body="true"
            :show-close="true"
        >
            <el-card class="box-card" style="margin: 0px 10px 10px 0px; width: 100%">
                <div class="text row">
                    <ex-edit-table-form
                        ref="editTableForm"
                        :rules="switchRules"
                        size="mini"
                        :table-props="{
                            stripe: true,
                            border: true,
                            data: switchData,
                            size: 'mini',
                            height: '300px',
                        }"
                        table-style="width: 100%"
                        class="task-book-info"
                        :columns="switchColumns"
                        :rowSave="handleSwitchSave"
                        :formItems="switchItems"
                        :disabled="!editable"
                    >
                        <template slot="append">
                            <el-table-column
                                align="center"
                                label="操作"
                                type="action"
                                width="70"
                                fixed="right"
                                v-if="editable"
                            >
                                <template slot-scope="scope">
                                    <el-button :data-delindex="scope" @click="handleEditSwitchDelete(scope)" type="text"
                                        >删除</el-button
                                    >
                                </template>
                            </el-table-column>
                        </template>
                    </ex-edit-table-form>
                    <div
                        class="ui-align--center ui-font--xxlarg"
                        style="font-size: 24px"
                        v-if="editable"
                    >
                        <i class="el-icon-circle-plus" title="添加柜体开关" @click="handleRowAddSwitch"></i>
                    </div>
                </div>
            </el-card>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeModalHandle" size="mini">关闭</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import {genItems, pickItemByProp} from '@/utils/formItemUtil';

const switchType = {
    killerSwitch: '断路器',
    loadSwitch: '负荷开关',
};
function positiveNumer(rule, value, callback) {
    if (value === '') {
        callback();
    } else {
        let num = Number(value);
        if (num < 0) {
            callback(new Error('请输入正数'));
        } else if (isNaN(num)) {
            callback(new Error('请输入正数'));
        }
        callback();
    }
}
let baseObj = genItems(
    [
        {
            prop: 'number',
            itemType: 'input',
            label: '编号',
            rules: [
                {required: true, message: '请填写编号', trigger: 'blur'},
                {min: 2, max: 40, message: '长度在 2 到 40 个字符', trigger: 'blur'},
            ],
        },
        {
            prop: 'name',
            itemType: 'input',
            label: '名称',
            rules: [
                {required: true, message: '请填写名称', trigger: 'blur'},
                {min: 2, max: 40, message: '长度在 2 到 40 个字符', trigger: 'blur'},
            ],
        },
        /*{
            prop: 'title',
            itemType: 'input',
            label: '简称',
            rules: [
                {required: false, message: '请填写简称', trigger: 'blur'},
                {min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur'},
            ],
        },*/
        {
            prop: 'manufacturer',
            itemType: 'input',
            label: '生产厂家',
            rules: [
                {required: true, message: '请填写生产厂家', trigger: 'blur'},
                {min: 2, max: 40, message: '长度在 2 到 40 个字符', trigger: 'blur'},
            ],
        },
        {
            prop: 'manufacturingNumber',
            itemType: 'input',
            label: '出厂编号',
            rules: [
                {required: true, message: '请填写出厂编号', trigger: 'blur'},
                {min: 2, max: 40, message: '长度在 2 到 40 个字符', trigger: 'blur'},
            ],
        },
        {
            prop: 'propertyType',
            itemType: 'select',
            label: '资产性质',
            options: {
                option: [],
            },
            rules: [{required: true, message: '请填写资产性质', trigger: 'blur'}],
        },
        {
            prop: 'commissionDate',
            itemType: 'date',
            label: '投产日期',
            rules: [{required: true, message: '请选择投产日期', trigger: 'blur'}],
        },
        /*{
            prop: 'qualityAssuranceDate',
            itemType: 'date',
            label: '质保日期',
        },
        {
            prop: 'effectiveQualityAssuranceDate',
            itemType: 'date',
            label: '有效质保日期',
        },*/
        {
            prop: 'productionDate',
            itemType: 'date',
            label: '出厂日期',
        },
        {
            prop: 'relatedDrawingName',
            itemType: 'input',
            label: '关联图纸',
            options: {disabled: true},
        },
        {
            prop: 'id',
            itemType: 'input',
            label: 'id(自增序列)',
            hidden: true,
        },
        {
            prop: 'equipmentTypes',
            itemType: 'select',
            label: '设备类型',
            options: {
                option: [],
            },
        },
    ],
    {span: 8}
);
let netObj = genItems(
    [
        {
            prop: 'powerGridInfo.latitude',
            itemType: 'input',
            label: '纬度',
            options: {disabled: true},
        },
        {
            prop: 'powerGridInfo.longitude',
            itemType: 'input',
            label: '经度',
            options: {disabled: true},
        },
    ],
    {span: 12}
);
let boxObj = genItems(
    [
        {
            prop: 'type',
            itemType: 'select',
            label: '柜体性质',
            options: {
                option: [],
            },
            rules: [{required: true, message: '请选择柜体性质', trigger: 'blur'}],
            column: {
                width: 120,
            },
        },
        {
            prop: 'number',
            itemType: 'input',
            label: '柜体编号',
            rules: [
                {required: true, message: '请填写柜体编号', trigger: 'blur'},
                {min: 2, max: 40, message: '长度在 2 到 40 个字符', trigger: 'blur'},
            ],
            column: {
                width: 120,
            },
        },
        {
            prop: 'name',
            itemType: 'input',
            label: '柜体名称',
            rules: [
                {required: true, message: '请填写柜体名称', trigger: 'blur'},
                {min: 2, max: 40, message: '长度在 2 到 40 个字符', trigger: 'blur'},
            ],
            column: {
                width: 120,
            },
        },
        {
            prop: 'switchType',
            itemType: 'select',
            label: '开关类型',
            options: {
                option: []
            },
            rules: [{min: 1, max: 40, message: '长度在 1 到 40 个字符', trigger: 'blur'}],
            column: {
                width: 120,
            },
        },
        /*{
            prop: 'loadSwitch',
            itemType: 'input',
            label: '负荷开关',
            rules: [{min: 1, max: 40, message: '长度在 1 到 40 个字符', trigger: 'blur'}],
            column: {
                width: 120,
            },
        },*/
        {
            prop: 'model',
            itemType: 'input',
            label: '型号',
            rules: [{min: 2, max: 40, message: '长度在 2 到 40 个字符', trigger: 'blur'}],
            column: {
                width: 120,
            },
        },
        {
            prop: 'voltage',
            itemType: 'number',
            label: '额定电压(kV)',
            options: {
                precision: 2,
                min: 0,
                controls: false,
            },
            rules: [
                {required: true, message: '请填写额定电压', trigger: 'blur'},
                {validator: positiveNumer, trigger: 'blur'},
            ],
            column: {
                width: 120,
            },
        },
        {
            prop: 'current',
            itemType: 'number',
            label: '额定电流(A)',
            options: {
                precision: 2,
                min: 0,
                controls: false,
            },
            rules: [
                {required: true, message: '请填写额定电流', trigger: 'blur'},
                {validator: positiveNumer, trigger: 'blur'},
            ],
            column: {
                width: 120,
            },
        },
        {
            prop: 'frequency',
            itemType: 'number',
            label: '额定频率(Hz)',
            defaultValue: 50,
            options: {
                precision: 0,
                min: 0,
                controls: false,
            },
            rules: [
                {required: true, message: '请填写额定频率', trigger: 'blur'},
                {validator: positiveNumer, trigger: 'blur'},
            ],
            column: {
                width: 120,
            },
        },
        {
            prop: 'shortFrequencyVoltage',
            itemType: 'number',
            label: '额定短时工频耐受电压(kV)',
            options: {
                precision: 0,
                min: 0,
                controls: false,
            },
            rules: [
                {required: true, message: '请填写额定短时工频耐受电压', trigger: 'blur'},
                {validator: positiveNumer, trigger: 'blur'},
            ],
            column: {
                width: 180,
            },
        },
        {
            prop: 'peakCurrent',
            itemType: 'number',
            label: '额定峰值耐受电流(kA)',
            options: {
                precision: 0,
                min: 0,
                controls: false,
            },
            rules: [
                {required: true, message: '请填写额定峰值耐受电流', trigger: 'blur'},
                {validator: positiveNumer, trigger: 'blur'},
            ],
            column: {
                width: 160,
            },
        },
        {
            prop: 'lightningImpulseVoltage',
            itemType: 'number',
            label: '额定雷电冲击耐受电压(kV)',
            options: {
                precision: 0,
                min: 0,
                controls: false,
            },
            rules: [
                {required: true, message: '请填写额定雷电冲击耐受电压', trigger: 'blur'},
                {validator: positiveNumer, trigger: 'blur'},
            ],
            column: {
                width: 180,
            },
        },
        {
            prop: 'shortCurrent',
            itemType: 'number',
            label: '额定短时耐受电流(kA)',
            options: {
                precision: 0,
                min: 0,
                controls: false,
            },
            rules: [
                {required: true, message: '请填写额定短时耐受电流', trigger: 'blur'},
                {validator: positiveNumer, trigger: 'blur'},
            ],
            column: {
                width: 160,
            },
        },
        {
            prop: 'shortCurrentTime',
            itemType: 'select',
            label: '额定短时耐受电流时间(S)',
            options: {
                option: [3, 4, 5],
            },
            rules: [
                {required: true, message: '额定短时耐受电流时间(S)', trigger: 'blur'},
            ],
            column: {
                width: 160,
            },
        },
        {
            prop: 'protectionLevel',
            itemType: 'input',
            label: '防护等级',
            rules: [{min: 2, max: 40, message: '长度在 2 到 40 个字符', trigger: 'blur'}],
            column: {
                width: 120,
            },
        },
        {
            prop: 'earthingSwitchNumber',
            itemType: 'input',
            label: '接地开关编号',
            rules: [{min: 2, max: 40, message: '长度在 2 到 40 个字符', trigger: 'blur'}],
            column: {
                width: 120,
            },
        },
        /*{
            prop: 'earthingSwitchState',
            itemType: 'input',
            label: '接地开关状态',
            options: {
                option: ['分闸', '合闸', '检修'],
                disabled: true,
            },
            column: {
                width: 120,
            },
        },*/
        {
            prop: 'manufacturer',
            itemType: 'input',
            label: '生产厂家',
            rules: [{min: 2, max: 40, message: '长度在 2 到 40 个字符', trigger: 'blur'}],
            column: {
                width: 120,
            },
        },
        /* {
            prop: 'website',
            itemType: 'input',
            label: '网址',
            rules: [{min: 2, max: 40, message: '长度在 2 到 40 个字符', trigger: 'blur'}],
            column: {
                width: 120,
            },
        },*/
        {
            prop: 'hotline',
            itemType: 'input',
            label: '服务热线',
            rules: [{min: 2, max: 40, message: '长度在 2 到 40 个字符', trigger: 'blur'}],
            column: {
                width: 120,
            },
        },
        {
            prop: 'manufacturingNumber',
            itemType: 'input',
            label: '出厂编号',
            rules: [{min: 2, max: 40, message: '长度在 2 到 40 个字符', trigger: 'blur'}],
            column: {
                width: 120,
            },
        },
        {
            prop: 'manufacturingDate',
            itemType: 'date',
            label: '出厂日期',
            column: {
                width: 150,
            },
        },
    ],
    null,
    {forEditTable: true}
);
let switchObj = genItems(
    [
        {
            prop: 'type',
            itemType: 'select',
            label: '开关类型',
            options: {
                option: [],
            },
            rules: [{required: true, message: '请选择开关类型', trigger: 'blur'}],
        },
        {
            prop: 'name',
            itemType: 'input',
            label: '开关名称',
            rules: [
                {required: true, message: '请填写开关名称', trigger: 'blur'},
                {min: 2, max: 40, message: '长度在 2 到 40 个字符', trigger: 'blur'},
            ],
        },
        {
            prop: 'number',
            itemType: 'input',
            label: '开关编号',
            rules: [
                {required: true, message: '请填写开关编号', trigger: 'blur'},
                {min: 2, max: 40, message: '长度在 2 到 40 个字符', trigger: 'blur'},
            ],
        },
        {
            prop: 'state',
            itemType: 'select',
            label: '开关状态',
            options: {
                option: [],
            },
            rules: [{required: true, message: '请选择开关状态', trigger: 'blur'}],
        },
        {
            prop: 'model',
            itemType: 'input',
            label: '型号',
            rules: [
                {required: true, message: '请填写型号', trigger: 'blur'},
                {min: 2, max: 40, message: '长度在 2 到 40 个字符', trigger: 'blur'}
            ],
        },
    ],
    null,
    {forEditTable: true}
);
export default {
    name: 'RingMainUnitForm',
    props: {
        id: {
            type: Number,
            default: 0,
        },
        type: {
            type: String,
            default: 'add',
        },
    },
    computed: {
        editable() {
            return ['add', 'edit'].includes(this.type);
        },
    },
    data() {
        let {items: baseItems, values: baseValues, rules: baseRules} = baseObj;
        let {items: netItems, values: netValues, rules: netRules} = netObj;
        let allValues = {
            ...baseValues,
            ...netValues,
            files: [],
            powerGridInfo: {
                data: [],
            },
        };
        let allRules = {...baseRules, ...netRules};
        let {items: boxItems, rules: boxRules, columns: boxColumns} = boxObj;
        let {items: switchItems, rules: switchRules, columns: switchColumns} = switchObj;
        return {
            formData: allValues,
            formRules: allRules,
            boxColumns,
            boxItems,
            boxRules,
            boxData: [],
            baseItems,
            netItems,
            switchItems,
            switchRules,
            switchColumns,
            switchData: [],
            dialog: {
                visible: false,
                title: '',
            },
        };
    },
    watch: {
        'formData.id'(newV, oldV) {
            if (newV && newV !== oldV) {
                this.getBox();
            }
        },
    },
    created() {
        this.getRingMainUnits();

        const dictMap = [
            {items: this.baseItems, prop: 'propertyType', parentCode: 'device_nature_of_property_right'},
            {items: this.baseItems, prop: 'equipmentTypes', parentCode: 'equipment_classification'},
            {items: this.boxItems, prop: 'type', parentCode: 'ring_main_unit_box_type', columns: this.boxColumns},
            {
                items: this.switchItems,
                prop: 'state',
                parentCode: 'ring_main_unit_box_switch_state',
                columns: this.switchColumns,
            },
            {
                items: this.boxItems,
                prop: 'switchType',
                parentCode: 'ring_main_unit_box_switch_type',
                columns: this.boxColumns,
            },
        ];
        this.$client.listDictionaryChildrenArr(dictMap.map((it) => it.parentCode)).then((res) => {
            let s = res.data.ring_main_unit_box_switch_type;
            let arr = [];
            for (let i = 0; i < s.length; i++) {
                if (s[i].code === 'ring_main_unit_box_switch_type_1') {
                    arr.push(s[i]);
                }
                if (s[i].code === 'ring_main_unit_box_switch_type_2') {
                    arr.push(s[i]);
                }
            }
            res.data.ring_main_unit_box_switch_type = arr;
            let {data: resData} = res;
            for (let key in resData) {
                let data = resData[key];
                let dict = dictMap.filter((it) => it.parentCode === key)[0];
                let selectInput = pickItemByProp(dict.items, dict.prop);
                let option = data.map((it) => {
                    return {
                        key: it.name,
                        value: it.value,
                    };
                });
                // 为table columns设置formatter
                if (dict.columns) {
                    let formatter = (row) => {
                        let value = row[dict.prop];
                        if (!data || !value) {
                            return '';
                        }
                        const dictionary = data.find((item) => item.value === value);
                        if (dictionary) {
                            return dictionary.name;
                        }
                        return '';
                    };
                    let selectColumn = pickItemByProp(dict.columns, dict.prop);
                    if (selectColumn) {
                        this.$set(selectColumn, 'formatter', formatter);
                    }
                }
                // 设置input select设置options
                if (selectInput) {
                    this.$set(selectInput.options, 'option', option);
                }
            }
        });
        this.$client.listEquipmentCustomer().then((res) => {
            let data = res.data || [];
            let selectInput = pickItemByProp(this.baseItems, 'customerId');
            let option = data.map((it) => {
                return {
                    key: it.customerName,
                    value: it.id,
                };
            });
                // 设置input select设置options
            if (selectInput) {
                this.$set(selectInput.options, 'option', option);
            }
        });
    },
    methods: {
        closeModalHandle() {
            this.dialog.visible = false;
            this.switchData = [];
        },
        getRingMainUnits() {
            if (this.id) {
                this.$client.getRingMainUnits(this.id).then((res) => {
                    let formData = res.data;
                    if (!formData.powerGridInfo) {
                        formData.powerGridInfo = {
                            data: [],
                        };
                    }
                    this.formData = formData;
                });
            }
        },
        handleBoxSave(row) {
            if (row.id) {
                // 更新柜体
                this.$client.updateRingMainUnitsBoxes(this.formData.id, row.id, row).then((res) => {
                    this.getBox();
                });
            }
            /*else {
                // 新增柜体
                this.$client.insertRingMainUnitsBoxes(this.formData.id, row).then((res) => {
                    this.getBox();
                });
            }*/
        },
        handleSwitchSave(row) {
            if (row.id) {
                // 更新柜体
                this.$client.updateRingMainUnitsSwitches(this.formData.id, this._boxId, row.id, row).then((res) => {
                    this.getSwitch(this._boxId);
                });
            } else {
                // 新增柜体
                this.$client.insertRingMainUnitsSwitches(this.formData.id, this._boxId, row).then((res) => {
                    this.getSwitch(this._boxId);
                });
            }
        },
        getSwitch(boxId) {
            this.$client.getRingMainUnitsSwitches(this.formData.id, boxId).then((res) => {
                this.switchData = res.data;
            });
        },
        getBox() {
            this.$client.getRingMainUnitsBoxes(this.formData.id).then((res) => {
                this.boxData = res.data;
            });
        },
        handleRowAddBox() {
            this.boxData.push({});
            /*if (this.formData.id) {
                this.boxData.push({});
                console.log('handleRowAddBox -> push');
            } else {
                this.$message({
                    message: '请先保存户外开关站基本信息，再编辑柜体信息',
                    type: 'warning',
                });
            }*/
        },
        handleRowAddSwitch() {
            if (this._boxId) {
                this.switchData.push({});
            } else {
                this.$message({
                    message: '请先选择柜体开关对应的柜体',
                    type: 'warning',
                });
            }
        },
        handleEditBoxDelete({$index, row}) {
            if (row.id) {
                this.$confirm('确认删除？', '提示', {
                    confirmButtonClass: 'el-button el-button--mini el-button--primary',
                    type: 'warning',
                }).then(() => {
                    this.$client
                        .deleteRingMainUnitsBoxes(this.formData.id, row.id)
                        .then(() => {
                            this.getBox();
                        })
                        .catch((err) => {
                            this.$message.error(err.msg);
                            this.getBox();
                        });
                });
            } else {
                this.boxData.splice($index, 1);
            }
        },
        handleEditSwitchDelete({$index, row}) {
            if (row.id) {
                this.$confirm('确认删除？', '提示', {
                    confirmButtonClass: 'el-button el-button--mini el-button--primary',
                    type: 'warning',
                }).then(() => {
                    this.$client
                        .deleteRingMainUnitsSwitches(this.formData.id, this._boxId, row.id)
                        .then(() => {
                            this.getSwitch(this._boxId);
                        })
                        .catch((err) => {
                            this.$message.error(err.msg);
                            this.getSwitch(this._boxId);
                        });
                });
            } else {
                this.switchData.splice($index, 1);
            }
        },
        handleEditSwitchesContent({$index, row}) {
            if (row && row.id) {
                let boxId = row.id;
                this._boxId = boxId;
                this.dialog.visible = true;
                this.dialog.title = '柜体开关维护';
                this.getSwitch(boxId);
            }
        },
        uploadFileSucceed(res) {
            const {data} = res;
            this.formData.files.push(data.id);
            this.fileInfos.push(data);
        },
        //提交
        submitHandle(callback) {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    let ringMainUnitBoxes = this.boxData;
                    let data = {...this.formData, ringMainUnitBoxes};
                    if (this.formData.id) {
                        this.$client.updateRingMainUnits(data).then((res) => {
                            this.$message({
                                message: '操作成功！',
                                type: 'success',
                            });
                            let data = res.data;
                            this.formData = data;
                            callback();
                        });
                    } else {
                        this.$client.insertRingMainUnits(data).then((res) => {
                            this.$message({
                                message: '操作成功！',
                                type: 'success',
                            });
                            let data = res.data;
                            this.formData = data;
                            callback();
                        });
                    }
                }
            });
        },
    },
};
</script>
<style lang="less" scoped>
.el-icon-circle-plus{
    cursor: pointer;
}
</style>
