<template>
    <div class="ui-layout-container ui-layout-flex ui-layout-flex--column ui-height-100">
        <div class="ui-layout-content--full ui-form-actions">
            <!--<el-dropdown @command="addHandle" style="margin: 20px 20px 0px 20px" :hide-timeout="1000">
                <el-button type="primary" size="mini" style="border-radius: 4px">
                    + 新增
                    <i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                    &lt;!&ndash; <el-dropdown-item v-for="(item, key) in equipmentList" :command="item.name" :key="key"
                        >{{ item.cname }}
                    </el-dropdown-item> &ndash;&gt;
                    <el-cascader
                        v-model="addValue"
                        :options="equipmentClassifyOptions"
                        :props="{
                            value: 'code',
                            label: 'name',
                        }"
                        @change="addHandle"
                        clearable
                    ></el-cascader>
                </el-dropdown-menu>
            </el-dropdown>-->
            <!-- <el-button type="primary" size="mini" @click="editHandle">修改</el-button>
            <el-button type="primary" size="mini" @click="showDetail">详情</el-button>
            <el-button type="primary" size="mini" @click="deleteEquipment">删除</el-button> -->
            <!--<el-button type="primary" @click="" size="mini">任务管理</el-button>-->
            <el-cascader
                class="cascader"
                placeholder="新增"
                v-model="addValue"
                :options="equipmentClassifyOptions"
                :props="{
                            value: 'code',
                            label: 'name',
                        }"
                @change="addHandle"
                clearable
            ></el-cascader>
            <el-button
                type="primary"
                @click="handleDownloadTemplate"
                size="mini"
                style="border-radius: 4px; margin-right: 20px"
            >
                下载模板
            </el-button>
            <el-dropdown>
                <el-button type="primary" size="mini" style="border-radius: 4px">
                    导入
                    <i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                        v-for="(item, key) in equipmentList"
                        :command="item.name"
                        :key="key"
                        style="padding: 0"
                    >
                        <el-upload
                            :multiple="false"
                            :action="item.uploadUrl"
                            :headers="uploadHeaders"
                            :on-success="uploadFileSucceed"
                            :show-file-list="false"
                        >
                            <div style="padding: 0 10px">{{ item.cname }}</div>
                        </el-upload>
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
            <!-- <el-button type="primary" size="mini" @click="modifyEquipmentState">修改状态</el-button> -->
        </div>
        <div class="ui-layout-content--full ui-layout-flex--1" style="margin: 0px 20px 20px 20px">
            <ex-search-table-pagination
                ref="searchTable"
                :fetch="$client.getEquipmentList"
                :columns="columns"
                :formOptions="formOptions"
                :searchWhenSortChange="true"
                @current-change="selectHandle"
                :paramsMethod="searchParams"
            >
                <template slot="append">
                    <el-table-column align="center" label="操作" type="action" width="130" fixed="right">
                        <template slot-scope="scope">
                            <el-tooltip content="修改">
                                <div
                                    class="search-table-icon search-table-icon_edit"
                                    @click="editHandle(scope.row)"
                                    type="text"
                                ></div>
                            </el-tooltip>
                            <el-tooltip content="详情" placement="top" :open-delay="200">
                                <div
                                    class="search-table-icon search-table-icon_detail"
                                    @click="showDetail(scope.row)"
                                    type="text"
                                ></div>
                            </el-tooltip>
                            <el-tooltip content="删除" placement="top" :open-delay="200">
                                <div
                                    class="search-table-icon search-table-icon_delete"
                                    @click="deleteEquipment(scope.row)"
                                    type="text"
                                ></div>
                            </el-tooltip>
                            <el-tooltip content="修改状态" placement="top" :open-delay="200">
                                <div
                                    class="search-table-icon search-table-icon_chaobiao"
                                    @click="modifyEquipmentState(scope.row)"
                                    type="text"
                                ></div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </template>
                <template slot="gridIdSlot">
                    <el-form-item>
                        <tree-select
                            :clearable="true"
                            :options="gridTree"
                            v-model="gridId"
                            placeholder="请选择线路"
                        ></tree-select>
                    </el-form-item>
                </template>
            </ex-search-table-pagination>
        </div>
        <el-dialog
            :width="dialog.width"
            :top="dialog.top"
            :title="dialog.title"
            v-if="dialog.visible"
            :visible.sync="dialog.visible"
            :modal-append-to-body="true"
            :close-on-click-modal="false"
            :append-to-body="true"
            :show-close="true"
        >
            <component
                :is="dialog.componentType"
                ref="editForm"
                :id="dialog.currentId"
                :type="dialog.type"
                :mainCategory="dialog.mainCategory"
            >
            </component>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeModalHandle" size="mini">{{
                    dialog.type === 'detail' ? '关闭' : '取消'
                }}</el-button>
                <el-button type="primary" @click="submitHandle" size="mini" v-if="dialog.type !== 'detail'"
                    >确定</el-button
                >
            </span>
        </el-dialog>
    </div>
</template>

<script>
import MainSubstationForm from './MainSubstationForm';
import SubstationForm from './SubstationForm';
import WireForm from './WireForm';
import RingMainUnitForm from './RingMainUnitForm';
import TowerPoleForm from './TowerPoleForm';
import TowerPoleSwitchForm from './TowerPoleSwitchForm';
import {EQUIPMENT_MAIN_CATEGORY} from '@/utils/constants.js';
import EquipmentStateForm from './EquipmentStateForm';
// 主变电站 MainSubstation
// 变电站 substation,
// 杆塔 towerPole,
// 杆上开关 towerPoleSwitch,
// 户外开关站 ringNetworkCabinet,
// 线缆 wire,
const componentMap = {
    MAIN_SUBSTATION: 'MainSubstationForm',
    SUBSTATION: 'SubstationForm',
    TOWER_POLE: 'TowerPoleForm',
    TOWER_POLE_SWITCH: 'TowerPoleSwitchForm',
    RING_MAIN_UNIT: 'RingMainUnitForm',
    WIRE: 'WireForm',
};
const componentTitleMap = {
    MAIN_SUBSTATION: '主变电站',
    SUBSTATION: '箱式变电站',
    TOWER_POLE: '杆塔',
    TOWER_POLE_SWITCH: '杆上开关',
    RING_MAIN_UNIT: '户外开关站',
    WIRE: '线缆',
};
const equipmentClassifyOptions = [
    {
        code: 'equipment_classify_line',
        name: '配电线路设备',
        children: [
            {
                code: 'MAIN_SUBSTATION',
                name: '主变电站',
            },
            {
                code: 'SUBSTATION',
                name: '箱式变电站',
            },
            {
                code: 'TOWER_POLE',
                name: '塔杆',
            },
            {
                code: 'TOWER_POLE_SWITCH',
                name: '杆上开关',
            },
            {
                code: 'RING_MAIN_UNIT',
                name: '户外开关站',
            },
            {
                code: 'WIRE',
                name: '线缆',
            },
        ],
    },
    {
        code: 'equipment_classify_instation',
        name: '站内设备',
        children: [
            {
                code: 'equipment_classify_line_1',
                name: '断路器',
            },
            {
                code: 'equipment_classify_line_2',
                name: '开关',
            },
            {
                code: 'equipment_classify_line_3',
                name: '主变压器',
            },
            {
                code: 'equipment_classify_line_4',
                name: '母线',
            },
            {
                code: 'equipment_classify_line_5',
                name: '保护',
            },
        ],
    },
];

export default {
    name: 'Equipment',
    components: {
        MainSubstationForm,
        SubstationForm,
        WireForm,
        RingMainUnitForm,
        TowerPoleForm,
        TowerPoleSwitchForm,
        EquipmentStateForm,
    },
    data() {
        return {
            gridTree: [],
            gridId: null,
            equipmentList: [
                {
                    cname: '主变电站',
                    name: EQUIPMENT_MAIN_CATEGORY.MAIN_SUBSTATION,
                    uploadUrl: `${this.$client.serverUrl}business/mainSubstations/import`,
                },
                {
                    cname: '箱式变电站',
                    name: EQUIPMENT_MAIN_CATEGORY.SUBSTATION,
                    uploadUrl: `${this.$client.serverUrl}business/substations/import`,
                },
                {
                    cname: '杆塔',
                    name: EQUIPMENT_MAIN_CATEGORY.TOWER_POLE,
                    uploadUrl: `${this.$client.serverUrl}business/towerPoles/import`,
                },
                {
                    cname: '杆上开关',
                    name: EQUIPMENT_MAIN_CATEGORY.TOWER_POLE_SWITCH,
                    uploadUrl: `${this.$client.serverUrl}business/towerPoleSwitches/import`,
                },
                {
                    cname: '户外开关站',
                    name: EQUIPMENT_MAIN_CATEGORY.RING_MAIN_UNIT,
                    uploadUrl: `${this.$client.serverUrl}business/ringMainUnits/import`,
                },
                {
                    cname: '线缆',
                    name: EQUIPMENT_MAIN_CATEGORY.WIRE,
                    uploadUrl: `${this.$client.serverUrl}business/wires/import`,
                },
            ],
            circuitList: [],
            equipmentTypes: [],
            statusList: [],
            searchRules: {},
            selectedRow: null,
            dialog: {
                visible: false,
                title: '',
                currentId: '',
                componentType: '',
                mainCategory: null,
                width: '80%',
                top: '5%',
            },
            uploadHeaders: {
                token: this.$store.state.token,
            },
            uploadUrl: `${this.$client.serverUrl}business/substations/import`,
            equipmentClassifyOptions,
            addValue: '',
        };
    },
    methods: {
        handleDownloadTemplate() {
            const link = document.createElement('a');
            link.href = `${this.$client.serverUrl}base/static/excelImport/设备导入模板.zip?token=${this.$store.state.token}`;
            document.body.appendChild(link);
            link.click();
            setTimeout(() => {
                document.body.removeChild(link);
            }, 0);
        },
        uploadFileSucceed(ret) {
            if (ret.code === 0) {
                this.$message({
                    message: '导入成功！',
                    type: 'success',
                });
            } else {
                this.$message({
                    message: ret.msg,
                    type: 'warning',
                });
            }
        },
        // 获取数据列表
        selectHandle(val) {
            this.selectedRow = val;
        },
        closeModalHandle() {
            this.dialog.visible = false;
        },
        searchParams(params) {
            // params.isDeleted = this.isDeleted;
            params.gridId = this.gridId;
        },
        preValidate() {
            if (this.selectedRow == null || this.selectedRow.id == null || this.selectedRow.id === '') {
                this.$message.warning('请选择待操作数据');
                return false;
            }

            return true;
        },
        addHandle(code) {
            // debugger;
            if (code && code.length > 0) {
                let e = code[code.length - 1];
                let Title = componentTitleMap[e];
                let componen = componentMap[e];
                if (Title && componen) {
                    this.dialog = {
                        title: '新增' + Title,
                        currentId: null,
                        visible: true,
                        type: 'add',
                        componentType: componen,
                        width: '80%',
                        top: '5%',
                    };
                } else {
                    this.$message({
                        message: '该模板未定义',
                        type: 'warning',
                    });
                }
            }
        },
        editHandle(row) {
            // if (!this.preValidate()) {
            //     return;
            // }

            let {id, mainCategory} = row;

            this.dialog = {
                title: '编辑' + componentTitleMap[mainCategory],
                currentId: id,
                visible: true,
                type: 'edit',
                componentType: componentMap[mainCategory],
                width: '80%',
                top: '5%',
            };
        },
        deleteEquipment(row) {
            // if (!this.preValidate()) {
            //     return;
            // }

            let {id, mainCategory} = row;
            this.$confirm('确认删除？', '提示', {
                confirmButtonClass: 'el-button el-button--mini el-button--primary',
                type: 'warning',
            }).then(() => {
                switch (mainCategory) {
                    case EQUIPMENT_MAIN_CATEGORY.SUBSTATION:
                        this.$client.deleteSubstation(id).then(() => {
                            this.$message({
                                message: '操作成功！',
                                type: 'success',
                            });
                            this.$refs.searchTable.searchHandler();
                        });
                        break;
                    case EQUIPMENT_MAIN_CATEGORY.TOWER_POLE:
                        this.$client.deleteTowerPole(id).then(() => {
                            this.$message({
                                message: '操作成功！',
                                type: 'success',
                            });
                            this.$refs.searchTable.searchHandler();
                        });
                        break;
                    case EQUIPMENT_MAIN_CATEGORY.TOWER_POLE_SWITCH:
                        this.$client.deleteTowerPoleSwitch(id).then(() => {
                            this.$message({
                                message: '操作成功！',
                                type: 'success',
                            });
                            this.$refs.searchTable.searchHandler();
                        });
                        break;
                    case EQUIPMENT_MAIN_CATEGORY.RING_MAIN_UNIT:
                        this.$client.deleteRingMainUnits(id).then(() => {
                            this.$message({
                                message: '操作成功！',
                                type: 'success',
                            });
                            this.$refs.searchTable.searchHandler();
                        });
                        break;
                    case EQUIPMENT_MAIN_CATEGORY.WIRE:
                        this.$client.deleteWire(id).then(() => {
                            this.$message({
                                message: '操作成功！',
                                type: 'success',
                            });
                            this.$refs.searchTable.searchHandler();
                        });

                    case EQUIPMENT_MAIN_CATEGORY.MAIN_SUBSTATION:
                        this.$client.deleteMainSubstation(id).then(() => {
                            this.$message({
                                message: '操作成功！',
                                type: 'success',
                            });
                            this.$refs.searchTable.searchHandler();
                        });
                        break;
                    default:
                        break;
                }
            });
        },
        showDetail(row) {
            // if (!this.preValidate()) {
            //     return;
            // }
            let {id, mainCategory} = row;
            this.dialog = {
                title: componentTitleMap[mainCategory] + '详情',
                currentId: id,
                visible: true,
                type: 'detail',
                componentType: componentMap[mainCategory],
                width: '80%',
                top: '5%',
            };
        },
        submitHandle() {
            this.$refs.editForm.submitHandle((rest) => {
                this.closeModalHandle();
                this.$refs.searchTable.searchHandler(rest);
            });
        },
        modifyEquipmentState(row) {
            // if (!this.preValidate()) {
            //     return;
            // }
            let {id, mainCategory} = row;
            this.dialog = {
                title: '巡检',
                currentId: id,
                visible: true,
                type: this.statusList,
                mainCategory,
                componentType: 'EquipmentStateForm',
                width: '40%',
                top: '10%',
            };
        },
    },
    computed: {
        columns() {
            return [
                {
                    prop: 'name',
                    label: '设备名称',
                },
                {
                    prop: 'number',
                    label: '设备编号',
                },
                {
                    prop: 'gridName',
                    label: '所属线路',
                },
                {
                    prop: 'mainCategory',
                    label: '设备类型',
                    render: ({mainCategory, categoryName}) => {
                        let mainCategoryName;
                        // 1=变电站, 2=户外开关站, 3=箱式变电站, 4=联络杆塔, 5=断路器, 6=线缆, 7=架空导线, 99=用电用户
                        switch (mainCategory) {
                            case EQUIPMENT_MAIN_CATEGORY.SUBSTATION:
                                mainCategoryName = '箱式变电站';
                                break;
                            case EQUIPMENT_MAIN_CATEGORY.TOWER_POLE:
                                mainCategoryName = '杆塔';
                                break;
                            case EQUIPMENT_MAIN_CATEGORY.TOWER_POLE_SWITCH:
                                mainCategoryName = '杆上开关';
                                break;
                            case EQUIPMENT_MAIN_CATEGORY.RING_MAIN_UNIT:
                                mainCategoryName = '户外开关站';
                                break;
                            case EQUIPMENT_MAIN_CATEGORY.WIRE:
                                mainCategoryName = '线缆';
                                break;
                            case EQUIPMENT_MAIN_CATEGORY.MAIN_SUBSTATION:
                                mainCategoryName = '主变电站';
                                break;
                            default:
                                return '未知设备类型';
                        }
                        if (categoryName) {
                            return mainCategoryName + '-' + categoryName;
                        }
                        return mainCategoryName;
                    },
                },
                {
                    prop: 'state',
                    label: '设备状态',
                    render: ({state}) => {
                        let dictionary = this.statusList.find((dict) => dict.code === state);
                        if (dictionary) {
                            return dictionary.name;
                        }
                        return state;
                    },
                },
                {
                    prop: 'commissionDate',
                    label: '投产时间',
                },
            ];
        },
        formOptions() {
            return {
                resetBtnCallback: () => {
                    this.gridId = null;
                },
                forms: [
                    {
                        slotName: 'gridIdSlot',
                        placeholder: '请选择所属线路',
                        clearable: true,
                        filterable: true,
                    },
                    {
                        prop: 'Type',
                        itemType: 'select',
                        clearable: true,
                        filterable: true,
                        placeholder: '请选择设备分类',
                        options: this.equipmentTypes,
                        valueKey: 'value',
                        labelKey: 'name',
                    },
                    {
                        prop: 'mainCategory',
                        itemType: 'select',
                        clearable: true,
                        filterable: true,
                        placeholder: '请选择设备类型',
                        options: this.equipmentList,
                        valueKey: 'name',
                        labelKey: 'cname',
                    },
                    {
                        prop: 'status',
                        itemType: 'select',
                        clearable: true,
                        filterable: true,
                        placeholder: '请选择设备状态',
                        options: this.statusList,
                        valueKey: 'value',
                        labelKey: 'name',
                    },
                    {
                        prop: 'nameOrNumber',
                        itemType: 'input',
                        clearable: true,
                        placeholder: '请输入设备名称、编号',
                        options: this.isUseForForeignList,
                    },
                ],
            };
        },
    },

    created() {
        const dictMap = [
            {listName: 'statusList', parentCode: 'equipment_state'},
            {listName: 'equipmentTypes', parentCode: 'equipment_classification'},
        ];
        this.$client.listDictionaryChildrenArr(dictMap.map((it) => it.parentCode)).then((res) => {
            let {data: resData} = res;
            for (let key in resData) {
                let data = resData[key];
                let dict = dictMap.filter((it) => it.parentCode === key)[0];
                this[dict.listName] = data;
                console.log(dictMap);
            }
        });
        let codes = ['equipment_classify'];
        // this.$client.listDictionaryChildrenTrees(codes).then((res) => {
        //     this.equipmentClassifyOptions = res.data.equipment_classify.children;
        // });
        this.$client.getPowerGridsTree().then(({data = []}) => {
            this.gridTree = data;
        });
    },
};
</script>

<style lang="less" scoped>
/deep/.el-upload--text {
    width: 100%;
}
    .cascader {
        margin: 20px 10px 0px 20px;
        /deep/ .el-input__inner{
            width: 100px;
            background-color: rgb(39, 73, 165) !important;
            border-radius: 4px !important;
            border: 0;
        }
    }
</style>
