<!--线缆-->

<template>
    <el-form
        class="text item"
        :model="formData"
        :rules="formRules"
        ref="form"
        size="mini"
        :disabled="type === 'detail'"
    >
        <el-row :gutter="24">
            <el-col :span="24">
                <el-card class="box-card" style="margin: 0px 10px 10px 0px; width: 100%">
                    <div slot="header" class="clearfix">
                        <span>基本信息</span>
                    </div>
                    <div class="text item">
                        <el-row :gutter="24">
                            <el-col :span="6">
                                <el-form-item label="线缆类型" prop="category">
                                    <el-select v-model="formData.category" clearable style="width: 100%">
                                        <el-option
                                            v-for="item in types"
                                            :key="item.value"
                                            :label="item.name"
                                            :value="item.value"
                                        >
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="名称" prop="name">
                                    <el-input v-model="formData.name"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="简称" prop="title">
                                    <el-input v-model="formData.title"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="编号" prop="number">
                                    <el-input v-model="formData.number"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="型号" prop="model">
                                    <el-input v-model="formData.model"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="设备类型" prop="category">
                                    <el-select v-model="formData.equipmentType" clearable style="width: 100%">
                                        <el-option
                                            v-for="item in equipmentTypes"
                                            :key="item.value"
                                            :label="item.name"
                                            :value="item.value"
                                        >
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-row :gutter="24">
                            <el-col :span="6">
                                <el-form-item label="电压上限(V)" prop="voltageMax">
                                    <el-input-number
                                        v-model="formData.voltageMax"
                                        :precision="1"
                                        :step="1"
                                        style="width: 100%"
                                    ></el-input-number>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="电压下限(V)" prop="voltageMin">
                                    <el-input-number
                                        v-model="formData.voltageMin"
                                        :precision="1"
                                        :step="1"
                                        style="width: 100%"
                                    ></el-input-number>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="导芯数量" prop="coreQuantity">
                                    <el-select v-model="formData.coreQuantity" clearable style="width: 100%">
                                        <el-option label="1" value="1"></el-option>
                                        <el-option label="2" value="2"></el-option>
                                        <el-option label="3" value="3"></el-option>
                                        <el-option label="4" value="4"></el-option>
                                        <el-option label="5" value="5"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="导芯直径(mm)" prop="coreDiameter">
                                    <el-input-number
                                        v-model="formData.coreDiameter"
                                        :precision="0"
                                        :step="1"
                                        :min="0"
                                        style="width: 100%"
                                    ></el-input-number>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-row :gutter="24">
                            <el-col :span="6">
                                <el-form-item label="长度(m)" prop="length">
                                    <el-input-number
                                        v-model="formData.length"
                                        :precision="2"
                                        :step="1"
                                        :min="0"
                                        style="width: 100%"
                                    ></el-input-number>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="资产性质" prop="propertyType">
                                    <el-select v-model="formData.propertyType" style="width: 100%">
                                        <el-option
                                            v-for="item in propertyTypes"
                                            :key="item.value"
                                            :label="item.name"
                                            :value="item.value"
                                        >
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item :label="'出厂日期'" prop="productionDate">
                                    <el-date-picker
                                        v-model="formData.productionDate"
                                        type="date"
                                        style="width: 100%"
                                        :placeholder="'请选择'"
                                        value-format="yyyy-MM-dd"
                                    ></el-date-picker>
                                </el-form-item>
                            </el-col>
                            <!--<el-col :span="6">
                                <el-form-item :label="'质保日期'" prop="qualityAssuranceDate">
                                    <el-date-picker
                                        v-model="formData.qualityAssuranceDate"
                                        type="date"
                                        style="width: 100%"
                                        :placeholder="'请选择'"
                                        value-format="yyyy-MM-dd"
                                    ></el-date-picker>
                                </el-form-item>
                            </el-col>-->
                        </el-row>

                        <el-row :gutter="24">
                            <el-col :span="6">
                                <el-form-item :label="'投产日期'" prop="commissionDate">
                                    <el-date-picker
                                        v-model="formData.commissionDate"
                                        style="width: 100%"
                                        type="date"
                                        :placeholder="'请选择'"
                                        value-format="yyyy-MM-dd"
                                    ></el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="出厂编号" prop="manufacturingNumber">
                                    <el-input v-model="formData.manufacturingNumber"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="厂家/品牌" prop="manufacturer">
                                    <el-input v-model="formData.manufacturer"></el-input>
                                </el-form-item>
                            </el-col>
                            <!--<el-col :span="6">
                                <el-form-item label="所属用户" prop="customerId">
                                    <el-select v-model="formData.customerId" clearable style="width: 100%">
                                        <el-option
                                            v-for="item in equipmentCustomers"
                                            :key="item.id"
                                            :label="item.customerName"
                                            :value="item.id"
                                        >
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>-->
                        </el-row>
                    </div>
                </el-card>
            </el-col>
        </el-row>
        <el-row :gutter="24">
            <el-col :span="12">
                <el-card class="box-card" style="margin: 0px 10px 10px 0px; width: 100%">
                    <div slot="header" class="clearfix">
                        <span>配网信息</span>
                    </div>
                    <div class="text row">
                        <el-table :data="formData.powerGridInfo" style="width: 100%">
                            <el-table-column prop="powerGridName" label="线路"> </el-table-column>
                            <el-table-column prop="joinInputEquipmentName" label="进线设备"> </el-table-column>
                            <el-table-column prop="joinOutputEquipmentName" label="出线设备"> </el-table-column>
                            <el-table-column prop="address" label="线路状态"> </el-table-column>
                        </el-table>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="12">
                <el-card class="box-card" style="margin: 0px 10px 10px 0px; width: 100%">
                    <div slot="header" class="clearfix">
                        <span>设备照片</span>
                    </div>
                    <div class="text row">
                        <ex-uploader v-model="formData.files" :disabled="type === 'detail'"></ex-uploader>
                    </div>
                </el-card>
            </el-col>
        </el-row>
    </el-form>
</template>
<script>
export default {
    name: 'WireForm',
    props: {
        id: {
            type: Number,
            default: null,
        },
        type: {
            type: String,
            default: 'add',
        },
    },
    data() {
        return {
            uploadHeaders: {
                token: this.$store.state.token,
            },
            uploadUrl: this.$client.uploadFileUrl,
            formData: {
                id: null,
                category: null,
                name: null,
                title: null,
                number: null,
                model: null,
                voltageMax: null,
                voltageMin: null,
                coreQuantity: null,
                coreDiameter: null,
                length: null,
                customerId: null,
                propertyType: null,
                equipmentType: null,
                productionDate: null,
                commissionDate: null,
                qualityAssuranceDate: null,
                manufacturer: null,
                manufacturingNumber: null,
                contractor: null,
                files: [],
                powerGridInfo: [],
            },
            formRules: {
                category: [{required: false, message: '请选择线缆类型', trigger: 'blur'}],
                name: [
                    {required: true, message: '请填写名称', trigger: 'blur'},
                    {min: 2, max: 40, message: '长度在 2 到 40 个字符', trigger: 'blur'},
                ],
                title: [
                    {required: true, message: '请填写简称', trigger: 'blur'},
                    {min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur'},
                ],
                number: [
                    {required: true, message: '请填写编号', trigger: 'blur'},
                    {min: 2, max: 40, message: '长度在 2 到 40 个字符', trigger: 'blur'},
                ],
                model: [
                    {required: true, message: '请填写型号', trigger: 'blur'},
                    {min: 2, max: 40, message: '长度在 2 到 40 个字符', trigger: 'blur'},
                ],
                voltageMax: [{required: true, message: '请填写电压上限', trigger: 'blur'}],
                voltageMin: [{required: true, message: '请填写电压下限', trigger: 'blur'}],
                coreQuantity: [{required: true, message: '请选择导芯数量', trigger: 'blur'}],
                coreDiameter: [{required: true, message: '请填写导芯直径', trigger: 'blur'}],
                length: [{required: true, message: '请填写长度', trigger: 'blur'}],
                propertyType: [{required: true, message: '请选择资产性质', trigger: 'blur'}],
                productionDate: [{required: false, message: '请选择出厂日期', trigger: 'blur'}],
                commissionDate: [{required: true, message: '请选择投产日期', trigger: 'blur'}],
                qualityAssuranceDate: [{required: false, message: '请选择质保日期', trigger: 'blur'}],
                manufacturer: [
                    {required: false, message: '请填写厂商', trigger: 'blur'},
                    {min: 2, max: 40, message: '长度在 2 到 40 个字符', trigger: 'blur'},
                ],
                manufacturingNumber: [
                    {required: false, message: '请填写出厂编号', trigger: 'blur'},
                    {min: 2, max: 40, message: '长度在 2 到 40 个字符', trigger: 'blur'},
                ],
                contractor: [{required: false, message: '请选择施工单位', trigger: 'blur'}],
            },
            propertyTypes: [],
            types: [],
            equipmentCustomers: [],
            equipmentTypes: [],
        };
    },
    methods: {
        uploadFileSucceed(res) {
            const {data} = res;
            this.formData.files.push(data);
        },
        //提交
        submitHandle(callback) {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    if (this.formData.id) {
                        this.$client.updateWire(this.formData.id, this.formData).then((ret) => {
                            if (ret.code === 0) {
                                this.$message({
                                    message: '操作成功！',
                                    type: 'success',
                                });
                                if (callback) {
                                    callback();
                                }
                            } else {
                                this.$message({
                                    message: ret.msg,
                                    type: 'warning',
                                });
                            }
                        });
                    } else {
                        this.$client.insertWire(this.formData).then((ret) => {
                            if (ret.code === 0) {
                                this.$message({
                                    message: '操作成功！',
                                    type: 'success',
                                });
                                if (callback) {
                                    callback();
                                }
                            } else {
                                this.$message({
                                    message: ret.msg,
                                    type: 'warning',
                                });
                            }
                        });
                    }
                }
            });
        },
    },
    created() {
        const dictMap = [
            {listName: 'propertyTypes', parentCode: 'device_nature_of_property_right'},
            {listName: 'types', parentCode: 'wire_type'},
            {listName: 'equipmentTypes', parentCode: 'equipment_classification'},
        ];
        this.$client.listDictionaryChildrenArr(dictMap.map((it) => it.parentCode)).then((res) => {
            let {data: resData} = res;
            for (let key in resData) {
                let data = resData[key];
                let dict = dictMap.filter((it) => it.parentCode === key)[0];
                this[dict.listName] = data;
                console.log(key + '测试');
            }
        });
        this.$client.listEquipmentCustomer().then((res) => {
            this.equipmentCustomers = res.data || {};
        });
        if (this.id) {
            this.$client.getWire(this.id).then((ret) => {
                if (ret.code === 0) {
                    let formData = ret.data;
                    if (!formData.powerGridInfo) {
                        formData.powerGridInfo = [];
                    }
                    this.formData = formData;
                } else {
                    this.$message({
                        message: ret.msg,
                        type: 'warning',
                    });
                }
            });
        }
    },
};

</script>
<style lang="less" scoped>
.el-icon-circle-plus{
    cursor: pointer;
}
</style>
