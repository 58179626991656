<template>
    <el-form class="edit-form" :model="formData" :rules="formRules" :inline="true" ref="form" size="mini">
        <el-card class="box-card" style="margin: 0px 10px 10px 0px; width: 100%">
            <!--<div slot="header" class="clearfix">
                        <span>基本信息</span>
                    </div>-->

            <div class="text item">
                <el-row :gutter="24">
                    <el-col :span="12" class="row-item"> 设备名称：{{ viewData.name }} </el-col>
                    <el-col :span="12" class="row-item"> 设备编号：{{ viewData.number }} </el-col>
                    <el-col :span="12" class="row-item"> 设备类型：{{ viewData.mainCategoryName }} </el-col>
                    <el-col :span="12" class="row-item"> 现状态：{{ viewData.stateName }} </el-col>
                    <el-col :span="12" class="row-item"> 所属线路：{{ viewData.gridName }} </el-col>
                </el-row>
                <el-row :gutter="24" class="row-item">
                    <el-col>
                        <el-form-item label="新状态" prop="newState">
                            <el-select v-model="formData.newState" clearable>
                                <el-option
                                    v-for="item in type"
                                    :key="item.code"
                                    :label="item.name"
                                    :value="item.code"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </div>
        </el-card>
    </el-form>
</template>
<script>
export default {
    props: {
        id: {
            type: Number,
            default: null,
        },
        mainCategory: {
            type: String,
            default: null,
        },
        type: {
            type: Array,
            default: () => [],
        }
    },
    data() {
        return {
            viewData: {},
            formData: {
                newState: null,
                previousState: null,
            },
            formRules: {
                newState: [{required: true, message: '请选择设备的新状态', trigger: 'blur'}],
            }
        };
    },
    methods: {
        //提交
        submitHandle(callback) {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.$client.updateEquipmentState(this.mainCategory, this.id, this.formData).then((ret) => {
                        if (ret.code === 0) {
                            this.$message({
                                message: '操作成功！',
                                type: 'success',
                            });
                            if (callback) {
                                callback();
                            }
                        } else {
                            this.$message({
                                message: ret.msg,
                                type: 'warning',
                            });
                        }
                    });
                }
            });
        },
    },
    created() {
        this.$client.getEquipment(this.mainCategory, this.id).then((ret) => {
            let data = ret.data;
            this.viewData = data;
            this.formData.previousState = data.state;
        });
    },
};
</script>
<style lang="less" scoped>
.edit-form {
    width: 100%;
}
.row-item {
    margin-bottom: 20px;
}
</style>
