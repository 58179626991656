<!--变电站-->
<template>
    <div>
        <el-form :model="formData" :rules="formRules" ref="form" size="mini" :disabled="type === 'detail'">
            <el-row :gutter="24">
                <el-col :span="10">
                    <!-- &lt;!&ndash;基本信息&ndash;&gt; -->
                    <el-card class="box-card" style="margin: 0px 10px 10px 0px; width: 100%">
                        <div slot="header" class="clearfix">
                            <span>基本信息</span>
                        </div>
                        <div class="text row">
                            <el-row :gutter="24">
                                <el-col :span="12">
                                    <el-form-item label="设备名称" prop="name">
                                        <el-input v-model="formData.name"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="设备编号" prop="number">
                                        <el-input v-model="formData.number" :disabled="disable"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="资产性质" prop="propertyType">
                                        <el-select v-model="formData.propertyType" clearable style="width: 100%">
                                            <el-option
                                                v-for="item in propertyTypes"
                                                :key="item.value"
                                                :label="item.name"
                                                :value="item.value"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="设备分类" prop="equipmentType">
                                <!-- <el-input v-model="formData.equipmentType"></el-input> -->
                                        <el-select v-model="formData.equipmentType" clearable style="width: 100%">
                                            <el-option
                                                v-for="item in equipmentTypes"
                                                :key="item.value"
                                                :label="item.name"
                                                :value="item.value"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </div>
                    </el-card>
                    <!-- &lt;!&ndash;配网信息&ndash;&gt; -->
                    <el-card class="box-card" style="margin: 0px 10px 10px 0px; width: 100%">
                        <div slot="header" class="clearfix">
                            <span>配网信息</span>
                        </div>
                        <div class="text row">
                            <el-row :gutter="24">
                                <el-col :span="12">
                                    <el-form-item label="设备经度" prop="longitude">
                                        <el-input
                                            v-model="formData.powerGridInfo.longitude"
                                            disabled="disabled"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="设备纬度" prop="latitude">
                                        <el-input
                                            v-model="formData.powerGridInfo.latitude"
                                            disabled="disabled"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </div>
                    </el-card>
                    <!-- &lt;!&ndash;配网状态&ndash;&gt; -->
                    <el-card class="box-card" style="margin: 0px 10px 10px 0px; width: 100%">
                        <div slot="header" class="notice_title">
                            <span>配网状态</span>
                        </div>
                        <div class="text row">
                            <el-table :data="formData.powerGridInfo.data" style="width: 100%">
                                <el-table-column prop="boxName" label="设备"></el-table-column>
                                <el-table-column prop="wireName" label="连接线缆"></el-table-column>
                                <el-table-column prop="joinEquipmentName" label="连接设备"></el-table-column>
                                <el-table-column prop="address" label="线路状态"></el-table-column>
                            </el-table>
                        </div>
                    </el-card>
                    <!-- &lt;!&ndash;100kV母线&ndash;&gt; -->
                    <el-card class="box-card" style="margin: 0px 10px 10px 0px; width: 100%">
                        <div slot="header" class="clearfix">
                            <span>100kV母线</span>
                        </div>
                        <div class="text row">
                            <el-row :gutter="24">
                                <el-col :span="12">
                                    <el-form-item
                                        label="母线名称"
                                        prop="mainSubWireParentEntity.name"
                                    >
                                        <el-input v-model="formData.mainSubWireParentEntity.name"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item
                                        label="线缆型号"
                                        prop="mainSubWireParentEntity.model"
                                    >
                                        <el-input v-model="formData.mainSubWireParentEntity.model"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </div>
                    </el-card>
                    <!-- &lt;!&ndash;10kV母线&ndash;&gt; -->
                    <el-card class="box-card" style="margin: 0px 10px 10px 0px; width: 100%">
                        <div slot="header" class="clearfix">
                            <span>10kV母线</span>
                        </div>
                        <div class="text row">
                            <el-row :gutter="24">
                                <el-col :span="12">
                                    <el-form-item
                                        label="母线名称"
                                        prop="mainSubTransWireParentEntity.name"
                                    >
                                        <el-input v-model="formData.mainSubTransWireParentEntity.name"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item
                                        label="线缆型号"
                                        prop="mainSubTransWireParentEntity.model"
                                    >
                                        <el-input v-model="formData.mainSubTransWireParentEntity.model"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </div>
                    </el-card>
                    <!-- &lt;!&ndash;变压器&ndash;&gt; -->
                    <el-card class="box-card" style="margin: 0px 10px 10px 0px; width: 100%">
                        <div slot="header" class="clearfix">
                            <span>变压器</span>
                        </div>
                        <div class="text row">
                            <el-row :gutter="24">
                                <el-col :span="12">
                                    <el-form-item
                                        label="名称"
                                        prop="mainSubTransformerEntity.name"
                                    >
                                        <el-input v-model="formData.mainSubTransformerEntity.name"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item
                                        label="变压器类型"
                                        prop="mainSubTransformerEntity.type"
                                    >
                                        <el-select v-model="formData.mainSubTransformerEntity.type" clearable
                                                   style="width: 100%">
                                            <el-option
                                                v-for="item in transformerTypes"
                                                :key="item.value"
                                                :label="item.name"
                                                :value="item.value"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row :gutter="24">
                                <el-col :span="12">
                                    <el-form-item
                                        label="额定容量"
                                        prop="mainSubTransformerEntity.capacity"
                                    >
                                        <el-input v-model="formData.mainSubTransformerEntity.capacity"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item
                                        label="额定频率"
                                        prop="mainSubTransformerEntity.frequency"
                                    >
                                        <el-input v-model="formData.mainSubTransformerEntity.frequency"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row :gutter="24">
                                <el-col :span="12">
                                    <el-form-item
                                        label="生产厂家"
                                        prop="mainSubTransformerEntity.manufacturer"
                                    >
                                        <el-input v-model="formData.mainSubTransformerEntity.manufacturer"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item
                                        label="产品型号"
                                        prop="mainSubTransformerEntity.model"
                                    >
                                        <el-input v-model="formData.mainSubTransformerEntity.model"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row :gutter="24">
                                <el-col :span="12">
                                    <el-form-item
                                        label="出厂日期"
                                        prop="mainSubTransformerEntity.productionDate"
                                    >
                                        <el-date-picker
                                            v-model="formData.mainSubTransformerEntity.productionDate"
                                            type="date"
                                            style="width: 100%"
                                            :placeholder="'请选择'"
                                            value-format="yyyy-MM-dd"
                                        ></el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item
                                        label="投产日期"
                                        prop="mainSubTransformerEntity.commissionDate"
                                    >
                                        <el-date-picker
                                            v-model="formData.mainSubTransformerEntity.commissionDate"
                                            type="date"
                                            style="width: 100%"
                                            :placeholder="'请选择'"
                                            value-format="yyyy-MM-dd"
                                        ></el-date-picker>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row :gutter="24">
                                <!-- <el-col :span="12">
                                    <el-form-item
                                        label="保质日期"
                                        prop="mainSubTransformerEntity.qualityAssuranceDate"
                                    >
                                        <el-date-picker
                                            v-model="formData.mainSubTransformerEntity.qualityAssuranceDate"
                                            type="date"
                                            style="width: 100%"
                                            :placeholder="'请选择'"
                                            value-format="yyyy-MM-dd"
                                        ></el-date-picker>
                                        &lt;!&ndash;
                                        <el-input v-model="formData.mainSubTransformerEntity.qualityAssuranceDate"></el-input>&ndash;&gt;
                                    </el-form-item>
                                </el-col> -->
                            </el-row>
                        </div>
                    </el-card>
                    <!-- &lt;!&ndash;设备照片&ndash;&gt; -->
                    <el-card class="box-card" style="margin: 0px 10px 10px 0px; width: 100%">
                        <div slot="header" class="clearfix">
                            <span>设备照片</span>
                        </div>
                        <div class="text row">
                            <ex-uploader v-model="formData.files" :disabled="!editable"></ex-uploader>
                        </div>
                    </el-card>
                </el-col>
                <el-col :span="14">
                    <!-- &lt;!&ndash;进线&ndash;&gt; -->
                    <el-card class="box-card" style="margin: 0px 10px 10px 0px; width: 100%">
                        <div slot="header" class="clearfix">
                            <span>进线</span>
                        </div>
                        <div class="text row">
                            <el-row :gutter="24">
                                <el-col :span="8">
                                    <el-form-item
                                        label="进线名称"
                                        prop="mainSubWireInEntity.name"
                                    >
                                        <el-input v-model="formData.mainSubWireInEntity.name"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item
                                        label="线缆信息"
                                        prop="mainSubWireInEntity.info"
                                    >
                                        <el-input v-model="formData.mainSubWireInEntity.info"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <ex-edit-table-form
                                :rules="switchRules"
                                size="mini"
                                :table-props="{
                                    stripe: true,
                                    border: true,
                                    data: formData.mainSubSwitchIn,
                                    size: 'mini',
                                    height: '260px',
                                }"
                                :disabled="!editable"
                                table-style="width: 100%"
                                class="task-book-info"
                                :rowSave="saveSwitch"
                            >
                                <template slot-scope="editScope">
                                    <el-table-column label="设备信息">
                                        <el-table-column align="center" label="名称">
                                            <template slot-scope="scope">
                                                <el-form-item v-if="scope.row === editScope.editingRow" prop="name">
                                                    <el-input v-model="scope.row.name"></el-input>
                                                </el-form-item>
                                                <span v-else>{{ scope.row.name }}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column align="center" label="编号">
                                            <template slot-scope="scope">
                                                <el-form-item v-if="scope.row === editScope.editingRow" prop="number">
                                                    <el-input v-model="scope.row.number"></el-input>
                                                </el-form-item>
                                                <span v-else>{{ scope.row.number }}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column align="center" label="设备类型" width="200px">
                                            <template slot-scope="scope">
                                                <el-form-item v-if="scope.row === editScope.editingRow">
                                                    <el-select
                                                        v-model="scope.row.type"
                                                    >
                                                        <el-option
                                                            v-for="item in mainSubSwitchType"
                                                            :key="item.value"
                                                            :label="item.name"
                                                            :value="item.value"
                                                        >
                                                        </el-option>
                                                    </el-select>
                                                </el-form-item>
                                                <span v-else>{{
                                                    getDictName(mainSubSwitchType, scope.row.type)
                                                }}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column align="center" label="状态">
                                            <template slot-scope="scope">
                                                <el-form-item v-if="scope.row === editScope.editingRow">
                                                    <el-select
                                                        v-model="scope.row.state"
                                                    >
                                                        <el-option
                                                            v-for="item in switchState"
                                                            :key="item.value"
                                                            :label="item.name"
                                                            :value="item.value"
                                                        >
                                                        </el-option>
                                                    </el-select>
                                                </el-form-item>
                                                <span v-else>{{
                                                    getDictName(switchState, scope.row.state)
                                                }}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            align="center"
                                            label="操作"
                                            type="action"
                                            width="160"
                                            fixed="right"
                                        >
                                            <template slot-scope="scope">
                                                <el-button
                                                    :data-delindex="scope"
                                                    @click="deleteSwitch(scope)"
                                                    type="text"
                                                    v-if="editable"
                                                >删除
                                                </el-button>
                                            </template>
                                        </el-table-column>
                                    </el-table-column>
                                </template>
                            </ex-edit-table-form>
                            <div class="ui-align&#45;&#45;center ui-font&#45;&#45;xxlarg" style="font-size: 24px" v-if="editable">
                                <i class="el-icon-circle-plus" title="添加开关" @click="addSwitch('WIRE_IN')"></i>
                            </div>
                        </div>
                    </el-card>
                    <!-- &lt;!&ndash;变压器高压侧&ndash;&gt; -->
                    <el-card class="box-card" style="margin: 0px 10px 10px 0px; width: 100%">
                        <div slot="header" class="clearfix">
                            <span>变压器高压侧</span>
                        </div>
                        <div class="text row">
                            <el-row :gutter="24">
                                <el-col :span="8">
                                    <el-form-item
                                        label="名称"
                                        prop="mainSubTransformerHighEntity.name"
                                    >
                                        <el-input v-model="formData.mainSubTransformerHighEntity.name"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item
                                        label="产品型号"
                                        prop="mainSubTransformerHighEntity.model"
                                    >
                                        <el-input v-model="formData.mainSubTransformerHighEntity.model"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item
                                        label="额定频率"
                                        prop="mainSubTransformerHighEntity.frequency"
                                    >
                                        <el-input v-model="formData.mainSubTransformerHighEntity.frequency"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row :gutter="24">
                                <el-col :span="8">
                                    <el-form-item
                                        label="额定电压"
                                        prop="mainSubTransformerHighEntity.voltage"
                                    >
                                        <el-input v-model="formData.mainSubTransformerHighEntity.voltage"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item
                                        label="额定容量"
                                        prop="mainSubTransformerHighEntity.capacity"
                                    >
                                        <el-input v-model="formData.mainSubTransformerHighEntity.capacity"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <ex-edit-table-form
                                :rules="switchRules"
                                size="mini"
                                :table-props="{
                                    stripe: true,
                                    border: true,
                                    data: formData.mainSubSwitchHigh,
                                    size: 'mini',
                                    height: '260px',
                                }"
                                :disabled="!editable"
                                table-style="width: 100%"
                                class="task-book-info"
                                :rowSave="saveSwitch"
                            >
                                <template slot-scope="editScope">
                                    <el-table-column label="设备信息">
                                        <el-table-column align="center" label="名称">
                                            <template slot-scope="scope">
                                                <el-form-item v-if="scope.row === editScope.editingRow" prop="name">
                                                    <el-input v-model="scope.row.name"></el-input>
                                                </el-form-item>
                                                <span v-else>{{ scope.row.name }}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column align="center" label="编号">
                                            <template slot-scope="scope">
                                                <el-form-item v-if="scope.row === editScope.editingRow" prop="number">
                                                    <el-input v-model="scope.row.number"></el-input>
                                                </el-form-item>
                                                <span v-else>{{ scope.row.number }}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column align="center" label="设备类型" width="200px">
                                            <template slot-scope="scope">
                                                <el-form-item v-if="scope.row === editScope.editingRow">
                                                    <el-select
                                                        v-model="scope.row.type"
                                                    >
                                                        <el-option
                                                            v-for="item in mainSubSwitchType"
                                                            :key="item.value"
                                                            :label="item.name"
                                                            :value="item.value"
                                                        >
                                                        </el-option>
                                                    </el-select>
                                                </el-form-item>
                                                <span v-else>{{
                                                    getDictName(mainSubSwitchType, scope.row.type)
                                                }}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column align="center" label="状态">
                                            <template slot-scope="scope">
                                                <el-form-item v-if="scope.row === editScope.editingRow">
                                                    <el-select
                                                        v-model="scope.row.state"
                                                    >
                                                        <el-option
                                                            v-for="item in switchState"
                                                            :key="item.value"
                                                            :label="item.name"
                                                            :value="item.value"
                                                        >
                                                        </el-option>
                                                    </el-select>
                                                </el-form-item>
                                                <span v-else>{{
                                                    getDictName(switchState, scope.row.state)
                                                }}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            align="center"
                                            label="操作"
                                            type="action"
                                            width="160"
                                            fixed="right"
                                        >
                                            <template slot-scope="scope">
                                                <el-button
                                                    :data-delindex="scope"
                                                    @click="deleteSwitch(scope)"
                                                    type="text"
                                                    v-if="editable"
                                                >删除
                                                </el-button>
                                            </template>
                                        </el-table-column>
                                    </el-table-column>
                                </template>
                            </ex-edit-table-form>
                            <div class="ui-align&#45;&#45;center ui-font&#45;&#45;xxlarg" style="font-size: 24px" v-if="editable">
                                <i class="el-icon-circle-plus" title="添加开关" @click="addSwitch('TRANSFORMER_HIGH')"></i>
                            </div>
                        </div>
                    </el-card>
                    <!-- &lt;!&ndash;变压器低压侧&ndash;&gt; -->
                    <el-card class="box-card" style="margin: 0px 10px 10px 0px; width: 100%">
                        <div slot="header" class="clearfix">
                            <span>变压器低压侧</span>
                        </div>
                        <div class="text row">
                            <el-row :gutter="24">
                                <el-col :span="8">
                                    <el-form-item
                                        label="名称"
                                        prop="mainSubTransformerHighEntity.name"
                                        :rules="formRules.mainSubTransformerLowEntity.voltage"
                                    >
                                        <el-input v-model="formData.mainSubTransformerLowEntity.name"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item
                                        label="产品型号"
                                        prop="mainSubTransformerHighEntity.model"
                                        :rules="formRules.mainSubTransformerLowEntity.model"
                                    >
                                        <el-input v-model="formData.mainSubTransformerLowEntity.model"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item
                                        label="额定频率"
                                        prop="mainSubTransformerHighEntity.frequency"
                                        :rules="formRules.mainSubTransformerLowEntity.frequency"
                                    >
                                        <el-input v-model="formData.mainSubTransformerLowEntity.frequency"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row :gutter="24">
                                <el-col :span="8">
                                    <el-form-item
                                        label="额定电压"
                                        prop="mainSubTransformerHighEntity.voltage"
                                        :rules="formRules.mainSubTransformerLowEntity.voltage"
                                    >
                                        <el-input v-model="formData.mainSubTransformerLowEntity.voltage"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item
                                        label="额定容量"
                                        prop="mainSubTransformerHighEntity.capacity"
                                        :rules="formRules.mainSubTransformerLowEntity.capacity"
                                    >
                                        <el-input v-model="formData.mainSubTransformerLowEntity.capacity"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <ex-edit-table-form
                                :rules="switchRules"
                                size="mini"
                                :table-props="{
                                    stripe: true,
                                    border: true,
                                    data: formData.mainSubSwitchLow,
                                    size: 'mini',
                                    height: '260px',
                                }"
                                :disabled="!editable"
                                table-style="width: 100%"
                                class="task-book-info"
                                :rowSave="saveSwitch"
                            >
                                <template slot-scope="editScope">
                                    <el-table-column label="设备信息">
                                        <el-table-column align="center" label="名称">
                                            <template slot-scope="scope">
                                                <el-form-item v-if="scope.row === editScope.editingRow" prop="name">
                                                    <el-input v-model="scope.row.name"></el-input>
                                                </el-form-item>
                                                <span v-else>{{ scope.row.name }}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column align="center" label="编号">
                                            <template slot-scope="scope">
                                                <el-form-item v-if="scope.row === editScope.editingRow" prop="number">
                                                    <el-input v-model="scope.row.number"></el-input>
                                                </el-form-item>
                                                <span v-else>{{ scope.row.number }}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column align="center" label="设备类型" width="200px">
                                            <template slot-scope="scope">
                                                <el-form-item v-if="scope.row === editScope.editingRow">
                                                    <el-select
                                                        v-model="scope.row.type"
                                                    >
                                                        <el-option
                                                            v-for="item in mainSubSwitchType"
                                                            :key="item.value"
                                                            :label="item.name"
                                                            :value="item.value"
                                                        >
                                                        </el-option>
                                                    </el-select>
                                                </el-form-item>
                                                <span v-else>{{
                                                    getDictName(mainSubSwitchType, scope.row.type)
                                                }}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column align="center" label="状态">
                                            <template slot-scope="scope">
                                                <el-form-item v-if="scope.row === editScope.editingRow">
                                                    <el-select
                                                        v-model="scope.row.state"
                                                    >
                                                        <el-option
                                                            v-for="item in switchState"
                                                            :key="item.value"
                                                            :label="item.name"
                                                            :value="item.value"
                                                        >
                                                        </el-option>
                                                    </el-select>
                                                </el-form-item>
                                                <span v-else>{{
                                                    getDictName(switchState, scope.row.state)
                                                }}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            align="center"
                                            label="操作"
                                            type="action"
                                            width="160"
                                            fixed="right"
                                        >
                                            <template slot-scope="scope">
                                                <el-button
                                                    :data-delindex="scope"
                                                    @click="deleteSwitch(scope)"
                                                    type="text"
                                                    v-if="editable"
                                                >删除
                                                </el-button>
                                            </template>
                                        </el-table-column>
                                    </el-table-column>
                                </template>
                            </ex-edit-table-form>
                            <div class="ui-align&#45;&#45;center ui-font&#45;&#45;xxlarg" style="font-size: 24px" v-if="editable">
                                <i class="el-icon-circle-plus" title="添加开关" @click="addSwitch('TRANSFORMER_LOW')"></i>
                            </div>
                        </div>
                    </el-card>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <!-- &lt;!&ndash;变压器出线侧&ndash;&gt; -->
                <el-col :span="24">
                    <el-card class="box-card" style="margin: 0px 10px 10px 0px; width: 100%">
                        <div slot="header" class="clearfix">
                            <span>变压器出线侧</span>
                        </div>
                        <div class="text row">
                            <el-card class="box-card" style="margin: 0px 10px 10px 0px; width: 100%">
                                <div class="text row" style="margin-top: 30px">
                                    <ex-edit-table-form
                                        :rules="wireOutRules"
                                        size="mini"
                                        :table-props="{
                                    stripe: true,
                                    border: true,
                                    data: formData.wireOutList,
                                    size: 'mini',
                                    height: '260px',
                                }"
                                        :disabled="!editable"
                                        table-style="width: 100%"
                                        class="task-book-info"
                                        :rowSave="saveWireOut"
                                    >
                                        <template slot-scope="editScope">
                                            <el-table-column label="设备信息">
                                                <el-table-column align="center" label="名称" width="120">
                                                    <template slot-scope="scope">
                                                        <el-form-item v-if="scope.row === editScope.editingRow"
                                                                      prop="name">
                                                            <el-input v-model="scope.row.name"></el-input>
                                                        </el-form-item>
                                                        <span v-else>{{ scope.row.name }}</span>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column align="center" label="编号" width="120">
                                                    <template slot-scope="scope">
                                                        <el-form-item v-if="scope.row === editScope.editingRow"
                                                                      prop="number">
                                                            <el-input v-model="scope.row.number"></el-input>
                                                        </el-form-item>
                                                        <span v-else>{{ scope.row.number }}</span>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column align="center" label="变压器形式" width="120">
                                                    <template slot-scope="scope">
                                                        <el-form-item v-if="scope.row === editScope.editingRow"
                                                                      prop="transformerForm">
                                                            <el-input v-model="scope.row.transformerForm"></el-input>
                                                        </el-form-item>
                                                        <span v-else>{{ scope.row.transformerForm }}</span>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column align="center" label="短路阻抗" width="120">
                                                    <template slot-scope="scope">
                                                        <el-form-item v-if="scope.row === editScope.editingRow"
                                                                      prop="shortCircuitImpedance">
                                                            <el-input
                                                                v-model="scope.row.shortCircuitImpedance"></el-input>
                                                        </el-form-item>
                                                        <span v-else>{{ scope.row.shortCircuitImpedance }}</span>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column align="center" label="使用条件" width="120">
                                                    <template slot-scope="scope">
                                                        <el-form-item v-if="scope.row === editScope.editingRow"
                                                                      prop="useCondition">
                                                            <el-input v-model="scope.row.useCondition"></el-input>
                                                        </el-form-item>
                                                        <span v-else>{{ scope.row.useCondition }}</span>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column align="center" label="冷却方式" width="120">
                                                    <template slot-scope="scope">
                                                        <el-form-item v-if="scope.row === editScope.editingRow"
                                                                      prop="coolMode">
                                                            <el-input v-model="scope.row.coolMode"></el-input>
                                                        </el-form-item>
                                                        <span v-else>{{ scope.row.coolMode }}</span>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column align="center" label="防护等级" width="120">
                                                    <template slot-scope="scope">
                                                        <el-form-item v-if="scope.row === editScope.editingRow"
                                                                      prop="protectLevel">
                                                            <el-input v-model="scope.row.protectLevel"></el-input>
                                                        </el-form-item>
                                                        <span v-else>{{ scope.row.protectLevel }}</span>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column align="center" label="总质量" width="120">
                                                    <template slot-scope="scope">
                                                        <el-form-item v-if="scope.row === editScope.editingRow"
                                                                      prop="totalQuality">
                                                            <el-input v-model="scope.row.totalQuality"></el-input>
                                                        </el-form-item>
                                                        <span v-else>{{ scope.row.totalQuality }}</span>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column align="center" label="联结阻标号" width="120">
                                                    <template slot-scope="scope">
                                                        <el-form-item v-if="scope.row === editScope.editingRow"
                                                                      prop="couplingGroupGrade">
                                                            <el-input v-model="scope.row.couplingGroupGrade"></el-input>
                                                        </el-form-item>
                                                        <span v-else>{{ scope.row.couplingGroupGrade }}</span>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column align="center" label="产品型号" width="120">
                                                    <template slot-scope="scope">
                                                        <el-form-item v-if="scope.row === editScope.editingRow"
                                                                      prop="model">
                                                            <el-input v-model="scope.row.model"></el-input>
                                                        </el-form-item>
                                                        <span v-else>{{ scope.row.model }}</span>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column align="center" label="投产日期" width="120">
                                                    <template slot-scope="scope">
                                                        <el-form-item v-if="scope.row === editScope.editingRow"
                                                                      prop="commissionDate">
                                                            <el-date-picker v-model="scope.row.commissionDate"
                                                                            :placeholder="'请选择'"
                                                                            value-format="yyyy-MM-dd"
                                                                            style="width: 100%"></el-date-picker>
                                                        </el-form-item>
                                                        <span v-else>{{ scope.row.commissionDate }}</span>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column align="center" label="保质日期" width="120">
                                                    <template slot-scope="scope">
                                                        <el-form-item v-if="scope.row === editScope.editingRow"
                                                                      prop="qualityAssuranceDate">
                                                            <el-date-picker v-model="scope.row.qualityAssuranceDate"
                                                                            :placeholder="'请选择'"
                                                                            value-format="yyyy-MM-dd"
                                                                            style="width: 100%"></el-date-picker>
                                                        </el-form-item>
                                                        <span v-else>{{ scope.row.qualityAssuranceDate }}</span>
                                                    </template>
                                                </el-table-column>
                                                &lt;!&ndash;开关&ndash;&gt;
                                                <el-table-column align="center" label="开关名称" width="120">
                                                    <template slot-scope="scope">
                                                        <el-form-item v-if="scope.row === editScope.editingRow"
                                                                      prop="switchName">
                                                            <el-input v-model="scope.row.switchEntity.name"></el-input>
                                                        </el-form-item>
                                                        <span v-else>{{ scope.row.switchEntity.name }}</span>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column align="center" label="开关编号" width="120">
                                                    <template slot-scope="scope">
                                                        <el-form-item v-if="scope.row === editScope.editingRow"
                                                                      prop="switchNumber">
                                                            <el-input
                                                                v-model="scope.row.switchEntity.number"></el-input>
                                                        </el-form-item>
                                                        <span v-else>{{ scope.row.switchEntity.number }}</span>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column align="center" label="开关类型" width="120">
                                                    <template slot-scope="scope">
                                                        <el-form-item v-if="scope.row === editScope.editingRow">
                                                            <el-select
                                                                v-model="scope.row.switchEntity.type"
                                                            >
                                                                <el-option
                                                                    v-for="item in mainSubSwitchType"
                                                                    :key="item.value"
                                                                    :label="item.name"
                                                                    :value="item.value"
                                                                >
                                                                </el-option>
                                                            </el-select>
                                                        </el-form-item>
                                                        <span v-else>{{
                                                    getDictName(mainSubSwitchType, scope.row.switchEntity.type)
                                                }}</span>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column align="center" label="开关状态" width="120">
                                                    <template slot-scope="scope">
                                                        <el-form-item v-if="scope.row === editScope.editingRow">
                                                            <el-select
                                                                v-model="scope.row.switchEntity.state"
                                                            >
                                                                <el-option
                                                                    v-for="item in switchState"
                                                                    :key="item.value"
                                                                    :label="item.name"
                                                                    :value="item.value"
                                                                >
                                                                </el-option>
                                                            </el-select>
                                                        </el-form-item>
                                                        <span v-else>{{
                                                    getDictName(switchState, scope.row.switchEntity.state)
                                                }}</span>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column
                                                    align="center"
                                                    label="操作"
                                                    type="action"
                                                    fixed="right"
                                                >
                                                    <template slot-scope="scope">
                                                        <el-button
                                                            :data-delindex="scope"
                                                            @click="deleteWireOut(scope)"
                                                            type="text"
                                                            v-if="editable"
                                                        >删除
                                                        </el-button>
                                                    </template>
                                                </el-table-column>
                                            </el-table-column>
                                        </template>
                                    </ex-edit-table-form>
                                    <div class="ui-align&#45;&#45;center ui-font&#45;&#45;xxlarg" style="font-size: 24px"
                                         v-if="editable">
                                        <i class="el-icon-circle-plus" title="添加开关" @click="addWireOut"></i>
                                    </div>
                                </div>
                            </el-card>
                        </div>
                    </el-card>
                </el-col>
            </el-row>
        </el-form>
        <!-- <el-card class="box-card" style="margin: 0px 10px 10px 0px; width: 100%">
            <div slot="header" class="clearfix">
                <span>断路器</span>
            </div>
        </el-card>
        <el-card class="box-card" style="margin: 0px 10px 10px 0px; width: 100%">
            <div slot="header" class="clearfix">
                <span>隔离开关</span>
            </div>
        </el-card>
        <el-card class="box-card" style="margin: 0px 10px 10px 0px; width: 100%">
            <div slot="header" class="clearfix">
                <span>祝变压器</span>
            </div>
        </el-card>
        <el-card class="box-card" style="margin: 0px 10px 10px 0px; width: 100%">
            <div slot="header" class="clearfix">
                <span>母线</span>
            </div>
        </el-card>
        <el-card class="box-card" style="margin: 0px 10px 10px 0px; width: 100%">
            <div slot="header" class="clearfix">
                <span>保护</span>
            </div>
        </el-card> -->
    </div>
</template>

<script>
export default {
    name: 'MainSubstationForm',
    props: {
        id: {
            type: Number,
            default: null
        },
        type: {
            type: String,
            default: 'add'
        }
    },
    data() {
        return {
            formData: {
                id: null,
                number: null,
                name: null,
                propertyType: null,
                wireInId: null,
                equipmentType: null,
                state: null,
                longitude: null,
                latitude: null,
                mainSubWireParentEntity: {
                    id: null,
                    number: null,
                    substationId: null,
                    name: null,
                    model: null
                },
                mainSubTransWireParentEntity: {
                    id: null,
                    number: null,
                    substationId: null,
                    name: null,
                    model: null
                },

                powerGridInfo: {
                    data: []
                },
                mainSubTransformerEntity: {
                    id: null,
                    number: null,
                    substationId: null,
                    wireParentId: null,
                    name: null,
                    type: null,
                    voltageH: null,
                    voltageL: null,
                    capacity: null,
                    frequency: null,
                    manufacturer: null,
                    model: null,
                    productionDate: null,
                    commissionDate: null,
                    qualityAssuranceDate: null
                },
                mainSubTransformerHighEntity: {
                    id: null,
                    number: null,
                    transformerId: null,
                    substationId: null,
                    name: null,
                    voltage: null,
                    capacity: null,
                    frequency: null,
                    manufacturer: null,
                    model: null,
                    productionDate: null,
                    commissionDate: null,
                    qualityAssuranceDate: null
                },
                mainSubTransformerLowEntity: {
                    id: null,
                    number: null,
                    transformerId: null,
                    substationId: null,
                    name: null,
                    voltage: null,
                    capacity: null,
                    frequency: null,
                    manufacturer: null,
                    model: null,
                    productionDate: null,
                    commissionDate: null,
                    qualityAssuranceDate: null
                },
                mainSubWireInEntity: {
                    id: null,
                    number: null,
                    name: null,
                    info: null
                },
                mainSubSwitch: {
                    number: null,
                    name: null,
                    type: null,
                    state: null,
                    equipmentId: null,
                    equipmentType: null
                },
                mainSubSwitchHigh: [],
                mainSubSwitchLow: [],
                mainSubSwitchIn: [],
                wireOutList: [],
                mainSubWireOutEntity: {
                    id: null,
                    number: null,
                    name: null,
                    wireId: null,
                    transformerForm: null,
                    substationId: null,
                    shortCircuitImpedance: null,
                    useCondition: null,
                    coolMode: null,
                    protectLevel: null,
                    totalQuality: null,
                    couplingGroupGrade: null,
                    manufacturer: null,
                    model: null,
                    commissionDate: null,
                    qualityAssuranceDate: null
                }
            },
            disable: false,
            formRules: {
                name: [{required: true, message: '请填写主变电站名称', trigger: 'blur'}],
                number: [{required: true, message: '请填主变电站写编号', trigger: 'blur'}],
                transformerType: [{required: true, message: '请选择主变电站资产性质', trigger: 'blur'}],
                mainSubWireParentEntity: {
                    name: [{required: true, message: '请填写110kV母线名称', trigger: 'blur'}],
                    model: [{required: true, message: '请填写110kV母线线缆型号', trigger: 'blur'}]
                },
                mainSubTransWireParentEntity: {
                    name: [{required: true, message: '请填写10kV母线名称', trigger: 'blur'}],
                    model: [{required: true, message: '请填写10kV母线型号', trigger: 'blur'}]
                },
                mainSubTransformerEntity: {
                    name: [{required: true, message: '请填写变压器名称', trigger: 'blur'}],
                    type: [{required: true, message: '请选择变压器类型', trigger: 'blur'}],
                    capacity: [{required: true, message: '请填写变压器额定容量', trigger: 'blur'}],
                    frequency: [{required: true, message: '请填写变压器额定频率', trigger: 'blur'}],
                    manufacturer: [{required: true, message: '请填写变压器生产厂家', trigger: 'blur'}],
                    model: [{required: true, message: '请填写变压器产品型号', trigger: 'blur'}],
                    productionDate: [{required: true, message: '请填写变压器出厂日期', trigger: 'blur'}],
                    commissionDate: [{required: true, message: '请填写变压器投产日期', trigger: 'blur'}],
                    qualityAssuranceDate: [{required: true, message: '请填写变压器保质日期', trigger: 'blur'}]
                },
                mainSubWireInEntity: {
                    name: [{required: true, message: '请填写进线名称', trigger: 'blur'}],
                    info: [{required: true, message: '请填写进线型号', trigger: 'blur'}]
                },
                mainSubTransformerHighEntity: {
                    name: [{required: true, message: '请填写高压侧名称', trigger: 'blur'}],
                    capacity: [{required: true, message: '请填写高压侧额定容量', trigger: 'blur'}],
                    frequency: [{required: true, message: '请填写高压侧额定频率', trigger: 'blur'}],
                    manufacturer: [{required: true, message: '请填高压侧写生产厂家', trigger: 'blur'}],
                    model: [{required: true, message: '请填写高压侧产品型号', trigger: 'blur'}],
                    voltage: [{required: true, message: '请填写高压侧额定电压', trigger: 'blur'}]
                },
                mainSubTransformerLowEntity: {
                    name: [{required: true, message: '请填写低压侧名称', trigger: 'blur'}],
                    capacity: [{required: true, message: '请填写低压侧额定容量', trigger: 'blur'}],
                    frequency: [{required: true, message: '请填写低压侧额定频率', trigger: 'blur'}],
                    manufacturer: [{required: true, message: '请填写低压侧生产厂家', trigger: 'blur'}],
                    model: [{required: true, message: '请填写低压侧产品型号', trigger: 'blur'}],
                    voltage: [{required: true, message: '请填写低压侧额定电压', trigger: 'blur'}]
                },
            },
            switchRules: {
                name: [{required: true, message: '请填写开关名称', trigger: 'blur'}],
                number: [{required: true, message: '请填写开关编号', trigger: 'blur'}],
                type: [{required: true, message: '请选择开关类型', trigger: 'blur'}],
                state: [{required: true, message: '请选择开关状态', trigger: 'blur'}],
            },
            wireOutRules: {
                name: [{required: true, message: '请填写出线侧名称', trigger: 'blur'}],
                number: [{required: true, message: '请填写出线侧编号', trigger: 'blur'}],
                transformerForm: [{required: true, message: '请填写出线侧变压器形式', trigger: 'blur'}],
                shortCircuitImpedance: [{required: true, message: '请填写出线侧短路阻抗', trigger: 'blur'}],
                useCondition: [{required: true, message: '请填写出线侧使用条件', trigger: 'blur'}],
                coolMode: [{required: true, message: '请填写出线侧冷却方式', trigger: 'blur'}],
                protectLevel: [{required: true, message: '请填写出线侧防护等级', trigger: 'blur'}],
                totalQuality: [{required: true, message: '请填写出线侧总质量', trigger: 'blur'}],
                couplingGroupGrade: [{required: true, message: '请填写出线侧联结阻标号', trigger: 'blur'}],
                manufacturer: [{required: true, message: '请填写出线侧生产厂家', trigger: 'blur'}],
                model: [{required: true, message: '请填写出线侧产品型号', trigger: 'blur'}],
                commissionDate: [{required: true, message: '请填写出线侧投产日期', trigger: 'blur'}],
                qualityAssuranceDate: [{required: true, message: '请填写出线侧保质日期', trigger: 'blur'}],
                switchEntity: {
                    name: [{required: true, message: '请填写开关名称', trigger: 'blur'}],
                    number: [{required: true, message: '请填写开关编号', trigger: 'blur'}],
                    type: [{required: true, message: '请选择开关类型', trigger: 'blur'}],
                    state: [{required: true, message: '请选择开关状态', trigger: 'blur'}],
                },
            },
            propertyTypes: [],
            equipmentTypes: [],
            transformerTypes: [],
            mainSubSwitchType: [],
            switchState: []
        };
    },
    computed: {
        editable() {
            return ['add', 'edit'].includes(this.type);
        }
    },
    methods: {
        uploadFileSucceed(res) {
            const {data} = res;
            this.formData.files.push(data);
        },
        //提交
        submitHandle(callback) {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    if (this.formData.id) {
                        this.$client.updateMainSubstation(this.formData.id, this.formData).then((ret) => {
                            if (ret.code === 0) {
                                this.$message({
                                    message: '操作成功！',
                                    type: 'success'
                                });
                                if (callback) {
                                    callback();
                                }
                            } else {
                                this.$message({
                                    message: ret.msg,
                                    type: 'warning'
                                });
                            }
                        });
                    } else {
                        this.$client.insertMainSubstation(this.formData).then((ret) => {
                            if (ret.code === 0) {
                                this.$message({
                                    message: '操作成功！',
                                    type: 'success'
                                });
                                if (callback) {
                                    callback();
                                }
                            } else {
                                this.$message({
                                    message: ret.msg,
                                    type: 'warning'
                                });
                            }
                        });
                    }
                }
            });
        },

        //添加开关
        addSwitch(equipmentType) {
            let eType = equipmentType;
            if (this.formData.mainSubTransformerHighEntity.id) {
                let s = {
                    equipmentType: eType
                };
                if (equipmentType === 'TRANSFORMER_HIGH') {
                    this.formData.mainSubSwitchHigh.push(s);
                } else if (equipmentType === 'TRANSFORMER_LOW') {
                    this.formData.mainSubSwitchLow.push(s);
                } else if (equipmentType === 'WIRE_IN') {
                    this.formData.mainSubSwitchIn.push(s);
                }
            } else {
                this.$message({
                    message: '请先保存主变电站基本信息，再编辑开关',
                    type: 'warning'
                });
            }
        },
        //保存开关
        saveSwitch(row) {
            let eType = row.equipmentType;
            if (!eType) {
                this.$message({
                    message: '获取开关类型失败',
                    type: 'warning'
                });
                return;
            }
            let eId;
            if (eType === 'TRANSFORMER_HIGH') {
                eId = this.formData.mainSubTransformerHighEntity.id;
            } else if (eType === 'TRANSFORMER_LOW') {
                eId = this.formData.mainSubTransformerLowEntity.id;
            } else if (eType === 'WIRE_IN') {
                eId = this.formData.mainSubWireInEntity.id;
            }
            if (row.id) {
                // 更新开关
                this.$client.updateMainSubSwitch(row.id, row).then(() => {
                    this.getSubstationSwitch(eId, eType);
                });
            } else {
                // 新增开关
                this.$client.insertMainSubSwitch(eId, row).then(() => {
                    this.getSubstationSwitch(eId, eType);
                });
            }
        },
        //删除高压侧开关
        deleteSwitch({$index, row}) {
            let eId = row.equipmentId;
            let eType = row.equipmentType;
            if (row.id) {
                this.$confirm('确认删除？', '提示', {
                    confirmButtonClass: 'el-button el-button--mini el-button--primary',
                    type: 'warning'
                }).then(() => {
                    this.$client
                        .deleteMainSubSwitch(row.id)
                        .then(() => {
                            this.getSubstationSwitch(eId, eType);
                        })
                        .catch((err) => {
                            this.$message.error(err.msg);
                            this.getSubstationSwitch(eId, eType);
                        });
                });
            } else {
                this.formData.mainSubSwitchHigh.splice($index, 1);
            }
        },
        //获取开关
        getSubstationSwitch(eId, eType) {
            this.$client.queryMainSubSwitchByEquipment(eId, eType).then((res) => {
                if (eType === 'TRANSFORMER_HIGH') {
                    this.formData.mainSubSwitchHigh = res.data;
                } else if (eType === 'TRANSFORMER_LOW') {
                    this.formData.mainSubSwitchLow = res.data;
                } else if (eType === 'WIRE_IN') {
                    this.formData.mainSubSwitchIn = res.data;
                }
            });
        },
        //添加出线侧
        addWireOut() {
            let sId = this.formData.id;
            let wId = this.formData.mainSubTransWireParentEntity.id;
            if (sId && wId) {
                let s = {
                    substationId: sId,
                    wireId: wId,
                    switchEntity: {}
                };
                this.formData.wireOutList.push(s);
            } else {
                this.$message({
                    message: '请先保存主变电站基本信息，再编辑出线侧',
                    type: 'warning'
                });
            }
        },
        //保存出线侧
        saveWireOut(row) {
            let sId = this.formData.id;
            let wId = this.formData.mainSubTransWireParentEntity.id;
            this.$client.saveMainSubWireOut(row).then(() => {
                this.getWireOut(sId, wId);
            });
        },
        //删除出线侧
        deleteWireOut({$index, row}) {
            let uId = this.formData.id;
            if (row.id) {
                this.$confirm('确认删除？', '提示', {
                    confirmButtonClass: 'el-button el-button--mini el-button--primary',
                    type: 'warning'
                }).then(() => {
                    this.$client.deleteMainSubWireOutAndSwitch(uId, row.id).then(() => {
                        this.getWireOut(uId, row.id);
                    }).catch((err) => {
                        this.$message.error(err.msg);
                        this.getWireOut(uId, row.id);
                    });
                });
            } else {
                this.formData.wireOutList.splice($index, 1);
            }
        },
        //获取出线侧
        getWireOut(uId, wId) {
            this.$client.queryMainSubWireOutAndSwitch(uId, wId).then((res) => {
                this.formData.wireOutList = res.data;
            });
        },
        getDictName(dictionaries, value) {
            if (!dictionaries || !value) {
                return '';
            }
            const dictionary = dictionaries.find((item) => item.value === value);
            if (dictionary) {
                return dictionary.name;
            }
            return '';
        },
        //初始化formData
        initFormData() {
            if (!this.formData.mainSubSwitchHigh) {
                this.formData.mainSubSwitchHigh = [];
            }
            if (!this.formData.mainSubSwitchLow) {
                this.formData.mainSubSwitchLow = [];
            }
            if (!this.formData.mainSubSwitchIn) {
                this.formData.mainSubSwitchIn = [];
            }
            if (this.formData.wireOutList && this.formData.wireOutList.length) {
                this.formData.wireOutList.forEach(function(item) {
                    if (!item.switchEntity) {
                        let s = {
                            name: '',
                            number: '',
                        };
                        item.switchEntity = s;
                    }
                });
            } else {
                this.formData.wireOutList = [];
            }
        }
    },
    created() {
        const dictMap = [
            {listName: 'propertyTypes', parentCode: 'device_nature_of_property_right'},
            {listName: 'transformerTypes', parentCode: 'substation_transformer_type'},
            {listName: 'mainSubSwitchType', parentCode: 'main_sub_switch_type'},
            {listName: 'switchState', parentCode: 'main_sub_switch_state'},
            // {listName: 'equipmentTypes', parentCode: 'device_equipment_type'}
            {listName: 'equipmentTypes', parentCode: 'equipment_classification'},
        ];
        this.$client.listDictionaryChildrenArr(dictMap.map((it) => it.parentCode)).then((res) => {
            let {data: resData} = res;
            for (let key in resData) {
                let data = resData[key];
                let dict = dictMap.filter((it) => it.parentCode === key)[0];
                this[dict.listName] = data;
            }
        });
        if (this.id) {
            this.disable = true,
            this.$client.getMainSubstation(this.id).then((ret) => {
                if (ret.code === 0) {
                    const formData = ret.data || {};
                    if (!formData.mainSubWireParentEntity) {
                        formData.mainSubWireParentEntity = {};
                    }
                    if (!formData.mainSubTransformerHighEntity) {
                        formData.mainSubTransformerHighEntity = {};
                    }
                    if (!formData.mainSubTransformerLow) {
                        formData.mainSubTransformerLow = {};
                    }
                    if (!formData.powerGridInfo) {
                        formData.powerGridInfo = {
                            data: []
                        };
                    }
                    this.formData = formData;
                    this.initFormData();
                } else {
                    this.$message({
                        message: ret.msg,
                        type: '系统错误，请联系管理员'
                    });
                }
            });
        }
    }
};
</script>
<style lang="less" scoped>
    .el-icon-circle-plus {
        cursor: pointer;
    }
</style>
