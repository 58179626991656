<!--杆塔-->

<template>
    <div>
        <el-form :model="formData" :rules="formRules" ref="form" size="mini" :disabled="type === 'detail'">
            <el-row :gutter="24">
                <el-col :span="20">
                    <el-card class="box-card" style="margin: 0px 10px 10px 0px; width: 100%">
                        <div slot="header" class="clearfix">
                            <span>基本信息</span>
                        </div>
                        <div class="text row">
                            <el-row :gutter="24">
                                <el-col :span="5">
                                    <el-form-item label="设备类别" prop="category">
                                        <el-select v-model="formData.category" clearable style="width: 100%">
                                            <el-option
                                                v-for="item in equipmentCategories"
                                                :key="item.value"
                                                :label="item.name"
                                                :value="item.value"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="5">
                                    <el-form-item label="设备编号" prop="number">
                                        <el-input v-model="formData.number"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="5">
                                    <el-form-item label="名称" prop="name">
                                        <el-input v-model="formData.name"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="5">
                                    <el-form-item label="简称" prop="title">
                                        <el-input v-model="formData.title"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="5">
                                    <el-form-item label="施工单位" prop="contractor">
                                        <el-input v-model="formData.contractor"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="4">
                                    <el-form-item label="类型" prop="type">
                                        <el-select v-model="formData.type" clearable style="width: 100%">
                                            <el-option
                                                v-for="item in equipmentTypes"
                                                :key="item.value"
                                                :label="item.name"
                                                :value="item.value"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="5">
                                    <el-form-item label="资产性质" prop="propertyType">
                                        <el-select v-model="formData.propertyType" clearable style="width: 100%">
                                            <el-option
                                                v-for="item in natureOfPropertyRights"
                                                :key="item.value"
                                                :label="item.name"
                                                :value="item.value"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="5">
                                    <el-form-item label="稍径(mm)" prop="diameter">
                                        <el-select v-model="formData.diameter" clearable style="width: 100%">
                                            <el-option
                                                v-for="item in diameters"
                                                :key="item.value"
                                                :label="item.name"
                                                :value="item.value"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="5">
                                    <el-form-item label="厂家/品牌" prop="manufacturer">
                                        <el-input v-model="formData.manufacturer"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="5">
                                    <el-form-item label="型号" prop="model">
                                        <el-input v-model="formData.model"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="4">
                                    <el-form-item :label="'出厂日期'" prop="productionDate">
                                        <el-date-picker
                                            v-model="formData.productionDate"
                                            type="date"
                                            style="width: 100%"
                                            :placeholder="'请选择'"
                                            value-format="yyyy-MM-dd"
                                        ></el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="5">
                                    <el-form-item :label="'投产日期'" prop="commissionDate">
                                        <el-date-picker
                                            v-model="formData.commissionDate"
                                            style="width: 100%"
                                            type="date"
                                            :placeholder="'请选择'"
                                            value-format="yyyy-MM-dd"
                                        ></el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <!--<el-col :span="5">
                                    <el-form-item :label="'质保日期'" prop="qualityAssuranceDate">
                                        <el-date-picker
                                            v-model="formData.qualityAssuranceDate"
                                            type="date"
                                            style="width: 100%"
                                            :placeholder="'请选择'"
                                            value-format="yyyy-MM-dd"
                                        ></el-date-picker>
                                    </el-form-item>
                                </el-col>-->
                                <el-col :span="5">
                                    <el-form-item label="高压横担规格(m)" prop="highVoltageCrossArm">
                                        <el-select v-model="formData.highVoltageCrossArm" clearable style="width: 100%">
                                            <el-option
                                                v-for="item in highVoltageCrossArms"
                                                :key="item.value"
                                                :label="item.name"
                                                :value="item.value"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="5">
                                    <el-form-item label="设备类型" prop="customerId">
<!--                                        <el-input v-model="formData.equipmentType"></el-input>-->
                                        <el-select v-model="formData.equipmentType" clearable style="width: 100%">
                                            <el-option
                                                v-for="item in equipmentTypess"
                                                :key="item.value"
                                                :label="item.name"
                                                :value="item.value"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </div>
                    </el-card>
                    <el-card class="box-card" style="margin: 0px 10px 10px 0px; width: 100%">
                        <div slot="header" class="clearfix">
                            <span>附属设备信息</span>
                        </div>
                        <div class="text row">
                            <el-row :gutter="24">
                                <el-col :span="5">
                                    <el-form-item label="接地装置电阻值" prop="earthResistance">
                                        <el-input-number
                                            v-model="formData.earthResistance"
                                            :precision="2"
                                            style="width: 100%"
                                        ></el-input-number>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="5">
                                    <el-form-item label="防撞设施类型" prop="antiCollisionFacilities">
                                        <el-select
                                            v-model="formData.antiCollisionFacilities"
                                            clearable
                                            style="width: 100%"
                                        >
                                            <el-option
                                                v-for="item in antiCollisionFacilities"
                                                :key="item.value"
                                                :label="item.name"
                                                :value="item.value"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="5">
                                    <el-form-item label="防雷支柱绝缘子防雷支柱" prop="lightningProofPostInsulators">
                                        <el-select
                                            v-model="formData.lightningProofPostInsulators"
                                            clearable
                                            style="width: 100%"
                                        >
                                            <el-option
                                                v-for="item in lightningProofPostInsulators"
                                                :key="item.value"
                                                :label="item.name"
                                                :value="item.value"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="5">
                                    <el-form-item
                                        label="中压避雷器外护套类型"
                                        prop="midVoltageSurgeArresterOuterSheath"
                                    >
                                        <el-select
                                            v-model="formData.midVoltageSurgeArresterOuterSheath"
                                            clearable
                                            style="width: 100%"
                                        >
                                            <el-option
                                                v-for="item in midVoltageSurgeArresterOuterSheaths"
                                                :key="item.value"
                                                :label="item.name"
                                                :value="item.value"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="4">
                                    <el-form-item label="附挂情况" prop="attached">
                                        <el-select v-model="formData.attached" clearable style="width: 100%">
                                            <el-option
                                                v-for="item in attacheds"
                                                :key="item.value"
                                                :label="item.name"
                                                :value="item.value"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="5">
                                    <el-form-item label="是否有接地装置" prop="hasEarthDevice">
                                        <el-select v-model="formData.hasEarthDevice" clearable style="width: 100%">
                                            <el-option label="是" :value="true"></el-option>
                                            <el-option label="否" :value="false"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="5">
                                    <el-form-item label="是否有变压器" prop="hasEarthDevice">
                                        <el-select v-model="formData.hasTransformer" clearable style="width: 100%">
                                            <el-option label="是" :value="true"></el-option>
                                            <el-option label="否" :value="false"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="5">
                                    <el-form-item label="拉线系统规格(组)" prop="cableSystemSpecification">
                                        <el-input-number
                                            v-model="formData.cableSystemSpecification"
                                            :precision="0"
                                            style="width: 100%"
                                        ></el-input-number>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </div>
                    </el-card>
                </el-col>

                <el-col :span="4">
                    <el-card class="box-card" style="margin: 0px 10px 10px 0px; width: 100%; height: 535px">
                        <div slot="header" class="clearfix">
                            <span>配网信息</span>
                        </div>
                        <div class="text row">
                            <el-row :gutter="24">
                                <el-col :span="24">
                                    <el-form-item label="设备经度" prop="longitude">
                                        <el-input
                                            v-model="formData.powerGridInfo.longitude"
                                            readonly="readonly"
                                            :disabled="true"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <el-form-item label="设备纬度" prop="latitude">
                                        <el-input
                                            v-model="formData.powerGridInfo.latitude"
                                            readonly="readonly"
                                            :disabled="true"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <el-form-item label="交叉跨越物" prop="crossCrossing">
                                        <el-select v-model="formData.crossCrossing" clearable style="width: 100%">
                                            <el-option
                                                v-for="item in crossCrossings"
                                                :key="item.value"
                                                :label="item.name"
                                                :value="item.value"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <el-form-item label="设备地形" prop="terrain">
                                        <el-select v-model="formData.terrain" clearable style="width: 100%">
                                            <el-option
                                                v-for="item in terrains"
                                                :key="item.value"
                                                :label="item.name"
                                                :value="item.value"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <el-form-item label="电杆型式（米）" prop="terrainClearance">
                                        <el-input-number
                                            v-model="formData.terrainClearance"
                                            :precision="2"
                                            style="width: 100%"
                                        ></el-input-number>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </div>
                    </el-card>
                </el-col>
            </el-row>

            <el-row :gutter="24">
                <el-col :span="24">
                    <el-card class="box-card" style="margin: 0px 10px 10px 0px; width: 100%">
                        <div slot="header" class="clearfix">
                            <span>柱上开关</span>
                        </div>
                        <div class="text row">
                            <el-table :data="switchs" style="width: 100%">
                                <el-table-column prop="equipmentNumber" label="编号"> </el-table-column>
                                <el-table-column
                                    prop="category"
                                    label="类型"
                                    :formatter="(row) => getDictName(switchEquipmentCategories, row.category)"
                                >
                                </el-table-column>
                                <el-table-column prop="name" label="名称"> </el-table-column>
                                <el-table-column prop="id" label="ID"> </el-table-column>
                                <el-table-column prop="attached" label="附属设备"> </el-table-column>
                                <el-table-column prop="manufacturer" label="厂商"> </el-table-column>
                                <el-table-column prop="model" label="型号"> </el-table-column>
                                <el-table-column prop="manufacturingNumber" label="出厂编号"> </el-table-column>
                                <el-table-column prop="propertyType" label="资产性质"> </el-table-column>
                                <el-table-column prop="commissionDate" label="投产日期"> </el-table-column>
                                <el-table-column prop="productionDate" label="出厂日期"> </el-table-column>
                                <el-table-column prop="qualityAssuranceDate" label="质保日期"> </el-table-column>
                                <el-table-column
                                    prop="status"
                                    label="状态"
                                    :formatter="(row) => getDictName(equipmentStates, row.state)"
                                >
                                </el-table-column>
                                <el-table-column prop="workStatus" label="工作状态"> </el-table-column>
                                <el-table-column prop="cableId" label="归属线路"> </el-table-column>
                                <el-table-column prop="" label="依附线路"> </el-table-column>
                                <el-table-column prop="terrainClearance" label="离地高度"> </el-table-column>
                            </el-table>
                        </div>
                    </el-card>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="12">
                    <el-card class="box-card" style="margin: 0px 10px 10px 0px; width: 100%">
                        <div slot="header" class="notice_title">
                            <span>接线信息</span>
                        </div>
                        <div class="text row">
                            <el-table :data="formData.powerGridInfo.data" style="width: 100%">
                                <el-table-column prop="boxName" label="设备"> </el-table-column>
                                <el-table-column prop="wireName" label="连接线缆"> </el-table-column>
                                <el-table-column prop="joinEquipmentName" label="连接设备"> </el-table-column>
                                <el-table-column prop="address" label="线路状态"> </el-table-column>
                            </el-table>
                        </div>
                    </el-card>
                </el-col>
                <el-col :span="12">
                    <el-card class="box-card" style="margin: 0px 10px 10px 0px; width: 100%">
                        <div slot="header" class="clearfix">
                            <span>关联图纸</span>
                        </div>
                        <div class="text row">
                            <ex-uploader v-model="formData.files" :disabled="type === 'detail'"></ex-uploader>
                        </div>
                    </el-card>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>
<script>
export default {
    name: 'TowerPoleForm',
    props: {
        id: {
            type: Number,
            default: null,
        },
        type: {
            type: String,
            default: 'add',
        },
    },
    data() {
        return {
            uploadHeaders: {
                token: this.$store.state.token,
            },
            uploadUrl: this.$client.uploadFileUrl,
            formData: {
                id: null,
                category: '',
                number: '',
                name: '',
                title: '',
                contractor: '',
                type: '',
                propertyType: '',
                diameter: '',
                manufacturer: '',
                model: '',
                equipmentType: null,
                productionDate: '',
                commissionDate: '',
                qualityAssuranceDate: '',

                longitude: '',
                latitude: '',
                crossCrossing: '',
                terrain: '',
                highVoltageCrossArm: '',
                terrainClearance: '',
                customerId: null,

                hasEarthDevice: null,
                earthResistance: '',
                antiCollisionFacilities: '',
                lightningProofPostInsulators: '',
                midVoltageSurgeArresterOuterSheath: '',
                attached: '',
                hasTransformer: null,
                cableSystemSpecification: '',
                files: [],
                powerGridInfo: {
                    data: [],
                },
            },
            switchs: [],
            formRules: {
                category: [{required: true, message: '请选择设备类别', trigger: 'blur'}],
                number: [{required: true, message: '请填写设备编号', trigger: 'blur'}],
                name: [
                    {required: true, message: '请填写名称', trigger: 'blur'},
                    {min: 2, max: 40, message: '长度在 2 到 40 个字符', trigger: 'blur'},
                ],
                title: [
                    {required: true, message: '请填写简称', trigger: 'blur'},
                    {min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur'},
                ],
                contractor: [
                    {required: false, message: '请填写施工单位', trigger: 'blur'},
                    {min: 2, max: 40, message: '长度在 2 到 40 个字符', trigger: 'blur'},
                ],
                type: [{required: false, message: '请选择类型', trigger: 'blur'}],
                propertyType: [{required: true, message: '请选择资产性质', trigger: 'blur'}],
                diameter: [{required: false, message: '请选择稍径', trigger: 'blur'}],
                manufacturer: [
                    {required: false, message: '请填写厂家/品牌', trigger: 'blur'},
                    {min: 2, max: 40, message: '长度在 2 到 40 个字符', trigger: 'blur'},
                ],
                model: [
                    {required: false, message: '请填写型号', trigger: 'blur'},
                    {min: 2, max: 40, message: '长度在 2 到 40 个字符', trigger: 'blur'},
                ],
                productionDate: [{required: false, message: '请选择出厂日期', trigger: 'blur'}],
                commissionDate: [{required: true, message: '请选择投产日期', trigger: 'blur'}],
                qualityAssuranceDate: [{required: false, message: '请选择质保日期', trigger: 'blur'}],

                longitude: [{required: false, message: '请填写设备经度', trigger: 'blur'}],
                latitude: [{required: false, message: '请填写设备纬度', trigger: 'blur'}],
                crossCrossing: [{required: false, message: '请选择交叉跨越物', trigger: 'blur'}],
                terrain: [{required: false, message: '请选择设备地形', trigger: 'blur'}],
                highVoltageCrossArm: [{required: false, message: '请填写高压横担规格(m)', trigger: 'blur'}],
                terrainClearance: [{required: false, message: '请填写电杆型式(米)', trigger: 'blur'}],

                hasEarthDevice: [{required: false, message: '请选择是否有接地装置', trigger: 'blur'}],
                earthResistance: [{required: false, message: '请填写接地装置电阻值', trigger: 'blur'}],
                antiCollisionFacilities: [{required: false, message: '请选择防撞设施类型', trigger: 'blur'}],
                lightningProofPostInsulators: [
                    {required: false, message: '请选择防雷支柱绝缘子防雷支柱', trigger: 'blur'},
                ],
                midVoltageSurgeArresterOuterSheath: [
                    {required: false, message: '请选择中压避雷器外护套类型', trigger: 'blur'},
                ],
                attached: [{required: false, message: '请选择附挂情况', trigger: 'blur'}],
                hasTransformer: [{required: false, message: '请选择是否有变压器', trigger: 'blur'}],
                cableSystemSpecification: [{required: false, message: '请填写拉线系统规格', trigger: 'blur'}],
            },
            equipmentCategories: [],
            equipmentTypes: [],
            equipmentTypess: [],
            natureOfPropertyRights: [],
            diameters: [],
            crossCrossings: [],
            terrains: [],
            highVoltageCrossArms: [],
            antiCollisionFacilities: [],
            lightningProofPostInsulators: [],
            midVoltageSurgeArresterOuterSheaths: [],
            attacheds: [],
            switchEquipmentCategories: [],
            equipmentStates: [],
            equipmentCustomers: [],
        };
    },
    methods: {
        uploadFileSucceed(res) {
            const {data} = res;
            this.formData.files.push(data);
        },
        //提交
        submitHandle(callback) {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    if (this.formData.id) {
                        this.$client.updateTowerPole(this.formData.id, this.formData).then((ret) => {
                            if (ret.code === 0) {
                                this.$message({
                                    message: '操作成功！',
                                    type: 'success',
                                });
                                if (callback) {
                                    callback();
                                }
                            } else {
                                this.$message({
                                    message: ret.msg,
                                    type: 'warning',
                                });
                            }
                        });
                    } else {
                        this.$client.insertTowerPole(this.formData).then((ret) => {
                            if (ret.code === 0) {
                                this.$message({
                                    message: '操作成功！',
                                    type: 'success',
                                });
                                if (callback) {
                                    callback();
                                }
                            } else {
                                this.$message({
                                    message: ret.msg,
                                    type: 'warning',
                                });
                            }
                        });
                    }
                }
            });
        },
        getDictName(dictionaries, value) {
            if (!dictionaries || !value) {
                return '';
            }
            const dictionary = dictionaries.find((item) => item.value === value);
            if (dictionary) {
                return dictionary.name;
            }
            return '';
        },
    },
    created() {
        const dictMap = [
            {listName: 'equipmentCategories', parentCode: 'tower_pole_equipment_category'},
            {listName: 'equipmentTypes', parentCode: 'tower_pole_equipment_type'},
            {listName: 'natureOfPropertyRights', parentCode: 'device_nature_of_property_right'},
            {listName: 'diameters', parentCode: 'tower_pole_diameter'},
            {listName: 'crossCrossings', parentCode: 'tower_pole_cross_crossing'},
            {listName: 'terrains', parentCode: 'tower_pole_terrain'},
            {listName: 'highVoltageCrossArms', parentCode: 'tower_pole_high_voltage_cross_arm'},
            {listName: 'antiCollisionFacilities', parentCode: 'tower_pole_anti_collision_facilities'},
            {listName: 'lightningProofPostInsulators', parentCode: 'tower_pole_lightning_proof_post_insulators'},
            {
                listName: 'midVoltageSurgeArresterOuterSheaths',
                parentCode: 'tower_pole_mid_voltage_surge_arrester_outer_sheath',
            },
            {listName: 'attacheds', parentCode: 'tower_pole_attached'},
            {listName: 'switchEquipmentCategories', parentCode: 'tower_pole_switch_equipment_category'},
            {listName: 'equipmentStates', parentCode: 'equipment_state'},
            {listName: 'equipmentTypess', parentCode: 'equipment_classification'},
        ];
        this.$client.listDictionaryChildrenArr(dictMap.map((it) => it.parentCode)).then((res) => {
            let {data: resData} = res;
            for (let key in resData) {
                let data = resData[key];
                let dict = dictMap.filter((it) => it.parentCode === key)[0];
                this[dict.listName] = data;
            }
        });
        this.$client.listEquipmentCustomer().then((res) => {
            this.equipmentCustomers = res.data || {};
        });
        if (this.id) {
            this.$client.getTowerPole(this.id).then((ret) => {
                if (ret.code === 0) {
                    const {switchs, ...formData} = ret.data;
                    if (!formData.powerGridInfo) {
                        formData.powerGridInfo = {
                            data: [],
                        };
                    }
                    this.formData = formData;
                    this.switchs = switchs;
                } else {
                    this.$message({
                        message: ret.msg,
                        type: 'warning',
                    });
                }
            });
        }
    },
};
</script>
<style lang="less" scoped>
.el-icon-circle-plus{
    cursor: pointer;
}
</style>
