//一些公用的枚举数据
const TASK_STATUS = {
    RECONNAISSANCE: '10', //待勘察
    RECONNAISSANCE_SAVED: '11', //勘察已保存待提交
    COMPLETION_APPLY: '30', //验收申请
    COMPLETION_APPLY_SAVED: '31', //验收申请已保存待提交
    COMPLETION_ACCEPTANCE: '40', //竣工验收
    COMPLETION_ACCEPTANCE_SAVED: '41', //竣工验收已保存待提交
    DATUM_ARCHIVE: '50', //归档
    DATUM_ARCHIVE_SAVED: '51', //归档已保存待提交
};

//线路属性
const LINE_TYPE = {
    1: '工业线路',
    2: '商业/居民线路',
    3: '混合线路',
};

//接入方式
const ACCESS_TYPE = {
    10: '高可靠性供电（多电源）',
    20: '单电源接入',
    30: '住宅小区配电站环网接入',
};

const REASONS_POWER_POINT_SELECTION = {
    1: '其它',
    2: '供电可靠性较高',
    3: '经济性较优',
    4: '接火送电时效性高',
};

const EQUIPMENT_MAIN_CATEGORY = {
    MAIN_SUBSTATION: 'MAIN_SUBSTATION',
    SUBSTATION: 'SUBSTATION',
    TOWER_POLE: 'TOWER_POLE',
    TOWER_POLE_SWITCH: 'TOWER_POLE_SWITCH',
    RING_MAIN_UNIT: 'RING_MAIN_UNIT',
    WIRE: 'WIRE',
};

const EQUIPMENT_MAIN_CATEGORY_NAME = {
    MAIN_SUBSTATION: '主变电站',
    SUBSTATION: '变电站',
    TOWER_POLE: '联络杆塔',
    TOWER_POLE_SWITCH: '塔极开关',
    RING_MAIN_UNIT: '环网柜',
    WIRE: '电线',
};

export {
    TASK_STATUS,
    LINE_TYPE,
    ACCESS_TYPE,
    REASONS_POWER_POINT_SELECTION,
    EQUIPMENT_MAIN_CATEGORY,
    EQUIPMENT_MAIN_CATEGORY_NAME,
};
