<!--变电站-->

<template>
    <div>
        <el-form :model="formData" :rules="formRules" ref="form" size="mini" :disabled="type === 'detail'">
            <el-row :gutter="24">
                <el-col :span="10">
                    <el-card class="box-card" style="margin: 0px 10px 10px 0px; width: 100%">
                        <div slot="header" class="clearfix">
                            <span>基本信息</span>
                        </div>
                        <div class="text row">
                            <el-row :gutter="24">
                                <el-col :span="12">
                                    <el-form-item label="设备名称" prop="name">
                                        <el-input v-model="formData.name"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="设备简称" prop="title">
                                        <el-input v-model="formData.title"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="设备编号" prop="number">
                                        <el-input v-model="formData.number"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="厂家/品牌" prop="manufacturer">
                                        <el-input v-model="formData.manufacturer"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="型号" prop="model">
                                        <el-input v-model="formData.model"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item
                                        label="变压器类型"
                                        prop="transformerType"
                                        :rules="formRules.transformerType"
                                    >
                                        <el-select v-model="formData.transformerType" clearable style="width: 100%">
                                            <el-option
                                                v-for="item in transformerTypes"
                                                :key="item.value"
                                                :label="item.name"
                                                :value="item.value"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="资产性质" prop="propertyType">
                                        <el-select v-model="formData.propertyType" clearable style="width: 100%">
                                            <el-option
                                                v-for="item in propertyTypes"
                                                :key="item.value"
                                                :label="item.name"
                                                :value="item.value"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="12">
                                    <el-form-item label="出厂编号" prop="manufacturingNumber">
                                        <el-input v-model="formData.manufacturingNumber"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item :label="'出厂日期'" prop="productionDate">
                                        <el-date-picker
                                            v-model="formData.productionDate"
                                            type="date"
                                            style="width: 100%"
                                            :placeholder="'请选择'"
                                            value-format="yyyy-MM-dd"
                                        ></el-date-picker>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="12">
                                    <el-form-item :label="'投产日期'" prop="commissionDate">
                                        <el-date-picker
                                            v-model="formData.commissionDate"
                                            type="date"
                                            style="width: 100%"
                                            :placeholder="'请选择'"
                                            value-format="yyyy-MM-dd"
                                        ></el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <!--<el-col :span="12">
                                    <el-form-item :label="'质保日期'" prop="qualityAssuranceDate">
                                        <el-date-picker
                                            v-model="formData.qualityAssuranceDate"
                                            type="date"
                                            style="width: 100%"
                                            :placeholder="'请选择'"
                                            value-format="yyyy-MM-dd"
                                        ></el-date-picker>
                                    </el-form-item>
                                </el-col>-->

                                <el-col :span="12">
                                    <el-form-item label="容量(kVA)" prop="capacity">
                                        <el-input-number
                                            v-model="formData.capacity"
                                            :precision="0"
                                            :step="1"
                                            :min="0"
                                            style="width: 100%"
                                        ></el-input-number>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="高压侧电压(kV)" prop="highVoltage">
                                        <el-input-number
                                            v-model="formData.highVoltage"
                                            :precision="0"
                                            :step="1"
                                            :min="0"
                                            style="width: 100%"
                                        ></el-input-number>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="12">
                                    <el-form-item label="计算电流(A)" prop="current">
                                        <el-input-number
                                            v-model="formData.current"
                                            :precision="0"
                                            :step="1"
                                            :min="0"
                                            style="width: 100%"
                                        ></el-input-number>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="低压侧电压(kV)" prop="lowVoltage">
                                        <el-input-number
                                            v-model="formData.lowVoltage"
                                            :precision="0"
                                            :step="1"
                                            :min="0"
                                            style="width: 100%"
                                        ></el-input-number>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="所属用户" prop="customerId">
                                        <el-select v-model="formData.customerId" clearable style="width: 100%">
                                            <el-option
                                                v-for="item in equipmentCustomers"
                                                :key="item.id"
                                                :label="item.customerName"
                                                :value="item.id"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="供电电源点" prop="powerSupplyPoint">
                                        <el-input v-model="formData.powerSupplyPoint"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="供电电源点线缆" prop="powerSupplyLine">
                                        <el-input v-model="formData.powerSupplyLine"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="设备类型" prop="customerId">
                                        <el-select v-model="formData.equipmentType" clearable style="width: 100%">
                                            <el-option
                                                v-for="item in equipmentTypes"
                                                :key="item.value"
                                                :label="item.name"
                                                :value="item.value"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </div>
                    </el-card>

                    <el-card class="box-card" style="margin: 0px 10px 10px 0px; width: 100%">
                        <div slot="header" class="clearfix">
                            <span>配网信息</span>
                        </div>
                        <div class="text row">
                            <el-row :gutter="24">
                                <el-col :span="12">
                                    <el-form-item label="设备经度" prop="longitude">
                                        <el-input
                                            v-model="formData.powerGridInfo.longitude"
                                            disabled="disabled"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="设备纬度" prop="latitude">
                                        <el-input
                                            v-model="formData.powerGridInfo.latitude"
                                            disabled="disabled"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </div>
                    </el-card>

                    <el-card class="box-card" style="margin: 0px 10px 10px 0px; width: 100%">
                        <div slot="header" class="clearfix">
                            <span>变压器信息</span>
                        </div>
                        <div class="text row">
                            <el-row :gutter="24">
                                <el-col :span="12">
                                    <el-form-item
                                        label="名称"
                                        prop="transformerEntity.name"
                                        :rules="formRules.transformerEntity.name"
                                    >
                                        <el-input v-model="formData.transformerEntity.name"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item
                                        label="型号"
                                        prop="transformerEntity.model"
                                        :rules="formRules.transformerEntity.model"
                                    >
                                        <el-input v-model="formData.transformerEntity.model"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item
                                        label="挡位"
                                        prop="transformerEntity.voltage"
                                        :rules="formRules.transformerEntity.voltage"
                                    >
                                        <el-select
                                            v-model="formData.transformerEntity.voltage"
                                            clearable
                                            style="width: 100%"
                                        >
                                            <el-option
                                                v-for="item in transformerVoltages"
                                                :key="item.value"
                                                :label="item.name"
                                                :value="item.value"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item
                                        label="电压规格"
                                        prop="transformerEntity.voltageSpecification"
                                        :rules="formRules.transformerEntity.voltageSpecification"
                                    >
                                        <el-input-number
                                            v-model="formData.transformerEntity.voltageSpecification"
                                            :precision="2"
                                            :step="1"
                                            :min="0"
                                            style="width: 100%"
                                        ></el-input-number>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item
                                        label="重要参数1"
                                        prop="transformerEntity.param1"
                                        :rules="formRules.transformerEntity.param1"
                                    >
                                        <el-input v-model="formData.transformerEntity.param1" type="textarea"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item
                                        label="重要参数2"
                                        prop="transformerEntity.param2"
                                        :rules="formRules.transformerEntity.param2"
                                    >
                                        <el-input v-model="formData.transformerEntity.param2" type="textarea"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </div>
                    </el-card>

                    <el-card class="box-card" style="margin: 0px 10px 10px 0px; width: 100%">
                        <div slot="header" class="clearfix">
                            <span>设备照片</span>
                        </div>
                        <div class="text row">
                            <ex-uploader v-model="formData.files" :disabled="!editable"></ex-uploader>
                        </div>
                    </el-card>
                </el-col>
                <el-col :span="14">
                    <el-card class="box-card" style="margin: 0px 10px 10px 0px; width: 100%">
                        <div slot="header" class="clearfix">
                            <span>高压侧开关柜</span>
                        </div>
                        <div class="text row">
                            <el-row :gutter="24">
                                <el-col :span="8">
                                    <el-form-item
                                        label="编号"
                                        prop="highUnitEntity.number"
                                        :rules="formRules.highUnitEntity.number"
                                    >
                                        <el-input v-model="formData.highUnitEntity.number"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item
                                        label="名称"
                                        prop="highUnitEntity.name"
                                        :rules="formRules.highUnitEntity.name"
                                    >
                                        <el-input v-model="formData.highUnitEntity.name"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item
                                        label="尺寸"
                                        prop="highUnitEntity.size"
                                        :rules="formRules.highUnitEntity.size"
                                    >
                                        <el-input v-model="formData.highUnitEntity.size"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item
                                        label="型号"
                                        prop="highUnitEntity.model"
                                        :rules="formRules.highUnitEntity.model"
                                    >
                                        <el-input v-model="formData.highUnitEntity.model"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item
                                        label="容量(kVA)"
                                        prop="highUnitEntity.capacity"
                                        :rules="formRules.highUnitEntity.capacity"
                                    >
                                        <el-input-number
                                            v-model="formData.highUnitEntity.capacity"
                                            :precision="0"
                                            :step="1"
                                            :min="0"
                                            style="width: 100%"
                                        ></el-input-number>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item
                                        label="计算电流(A)"
                                        prop="highUnitEntity.current"
                                        :rules="formRules.highUnitEntity.current"
                                    >
                                        <el-input-number
                                            v-model="formData.highUnitEntity.current"
                                            :precision="0"
                                            :step="1"
                                            :min="0"
                                            style="width: 100%"
                                        ></el-input-number>
                                    </el-form-item>
                                </el-col>
                            </el-row>

                            <ex-edit-table-form
                                ref="highUnitEquipmentTableForm"
                                :rules="highUnitEquipmentRules"
                                size="mini"
                                :table-props="{
                                    stripe: true,
                                    border: true,
                                    data: highUnitEquipments,
                                    size: 'mini',
                                    height: '300px',
                                }"
                                :disabled="!editable"
                                table-style="width: 100%"
                                class="task-book-info"
                                :rowSave="saveHighUnitEquipment"
                            >
                                <template slot-scope="editScope">
                                    <el-table-column label="设备信息">
                                        <el-table-column align="center" label="设备类型" width="120px">
                                            <template slot-scope="scope">
                                                <el-form-item v-if="scope.row === editScope.editingRow" prop="type">
                                                    <el-select
                                                        v-model="scope.row.type"
                                                        @change="handleHighUnitEquipmentTypeChange($event, scope.row)"
                                                    >
                                                        <el-option
                                                            v-for="item in highUnitEquipmentTypes"
                                                            :key="item.value"
                                                            :label="item.name"
                                                            :value="item.value"
                                                        >
                                                        </el-option>
                                                    </el-select>
                                                </el-form-item>
                                                <span v-else>{{
                                                    getDictName(highUnitEquipmentTypes, scope.row.type)
                                                }}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column align="center" label="设备代号">
                                            <template slot-scope="scope">
                                                <el-form-item v-if="scope.row === editScope.editingRow" prop="code">
                                                    <el-input v-model="scope.row.code"></el-input>
                                                </el-form-item>
                                                <span v-else>{{ scope.row.code }}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column align="center" label="设备数量">
                                            <template slot-scope="scope">
                                                <el-form-item v-if="scope.row === editScope.editingRow" prop="quantity">
                                                    <el-input-number
                                                        v-model="scope.row.quantity"
                                                        :precision="2"
                                                        :controls="false"
                                                        :min="0"
                                                        style="width: 100%"
                                                    ></el-input-number>
                                                </el-form-item>
                                                <span v-else>{{ scope.row.quantity }}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column align="center" label="负荷开关上限（kV）">
                                            <template slot-scope="scope">
                                                <el-form-item
                                                    v-if="scope.row === editScope.editingRow && scope.row.type === '1'"
                                                    prop="loadSwitchMax"
                                                >
                                                    <el-input-number
                                                        v-model="scope.row.loadSwitchMax"
                                                        :precision="2"
                                                        :controls="false"
                                                        :min="0"
                                                        style="width: 100%"
                                                    ></el-input-number>
                                                </el-form-item>
                                                <span v-else>{{ scope.row.loadSwitchMax }}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column align="center" label="负荷开关下限（A）">
                                            <template slot-scope="scope">
                                                <el-form-item
                                                    v-if="scope.row === editScope.editingRow && scope.row.type === '1'"
                                                    prop="loadSwitchMin"
                                                >
                                                    <el-input-number
                                                        v-model="scope.row.loadSwitchMin"
                                                        :precision="2"
                                                        :controls="false"
                                                        :min="0"
                                                        style="width: 100%"
                                                    ></el-input-number>
                                                </el-form-item>
                                                <span v-else>{{ scope.row.loadSwitchMin }}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column align="center" label="短路熔断器规格（A）">
                                            <template slot-scope="scope">
                                                <el-form-item
                                                    v-if="scope.row === editScope.editingRow && scope.row.type === '2'"
                                                    prop="shortCircuitFuse"
                                                >
                                                    <el-input-number
                                                        v-model="scope.row.shortCircuitFuse"
                                                        :precision="2"
                                                        :controls="false"
                                                        :min="0"
                                                        style="width: 100%"
                                                    ></el-input-number>
                                                </el-form-item>
                                                <span v-else>{{ scope.row.shortCircuitFuse }}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column align="center" label="限流熔断器规格（A）">
                                            <template slot-scope="scope">
                                                <el-form-item
                                                    v-if="scope.row === editScope.editingRow && scope.row.type === '3'"
                                                    prop="currentLimiterFuse"
                                                >
                                                    <el-input-number
                                                        v-model="scope.row.currentLimiterFuse"
                                                        :precision="2"
                                                        :controls="false"
                                                        :min="0"
                                                        style="width: 100%"
                                                    ></el-input-number>
                                                </el-form-item>
                                                <span v-else>{{ scope.row.currentLimiterFuse }}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column align="center" label="肘型避雷器规格">
                                            <template slot-scope="scope">
                                                <el-form-item
                                                    v-if="scope.row === editScope.editingRow && scope.row.type === '4'"
                                                    prop="elbowArrester"
                                                >
                                                    <el-input v-model="scope.row.elbowArrester"></el-input>
                                                </el-form-item>
                                                <span v-else>{{ scope.row.elbowArrester }}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            align="center"
                                            label="操作"
                                            type="action"
                                            width="100"
                                            fixed="right"
                                        >
                                            <template slot-scope="scope">
                                                <el-button
                                                    :data-delindex="scope"
                                                    @click="deleteHighUnitEquipment(scope)"
                                                    type="text"
                                                    v-if="editable"
                                                    >删除</el-button
                                                >
                                            </template>
                                        </el-table-column>
                                    </el-table-column>
                                </template>
                            </ex-edit-table-form>
                            <div class="ui-align--center ui-font--xxlarg" style="font-size: 24px" v-if="editable">
                                <i class="el-icon-circle-plus" title="添加柜体信息" @click="addHighUnitEquipment"></i>
                            </div>
                        </div>
                    </el-card>

                    <el-card class="box-card" style="margin: 0px 10px 10px 0px; width: 100%">
                        <div slot="header" class="clearfix">
                            <span>低压侧户外开关站</span>
                        </div>
                        <div class="text row">
                            <el-row :gutter="24">
                                <el-col :span="8">
                                    <el-form-item
                                        label="名称"
                                        prop="lowUnitEntity.name"
                                        :rules="formRules.lowUnitEntity.name"
                                    >
                                        <el-input v-model="formData.lowUnitEntity.name"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item
                                        label="尺寸"
                                        prop="lowUnitEntity.size"
                                        :rules="formRules.lowUnitEntity.size"
                                    >
                                        <el-input v-model="formData.lowUnitEntity.size"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>

                            <ex-edit-table-form
                                ref="lowUnitBoxTableForm"
                                :rules="lowUnitBoxRules"
                                size="mini"
                                :table-props="{
                                    stripe: true,
                                    border: true,
                                    data: lowUnitBoxes,
                                    size: 'mini',
                                    height: '300px',
                                }"
                                :disabled="!editable"
                                table-style="width: 100%"
                                class="task-book-info"
                                :rowSave="saveLowUnitBox"
                            >
                                <template slot-scope="editScope">
                                    <el-table-column label="设备信息">
                                        <el-table-column align="center" label="编号" width="140px">
                                            <template slot-scope="scope">
                                                <el-form-item v-if="scope.row === editScope.editingRow" prop="number">
                                                    <el-input v-model="scope.row.number"></el-input>
                                                </el-form-item>
                                                <span v-else>{{ scope.row.number }}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column align="center" label="型号">
                                            <template slot-scope="scope">
                                                <el-form-item v-if="scope.row === editScope.editingRow" prop="model">
                                                    <el-input v-model="scope.row.model"></el-input>
                                                </el-form-item>
                                                <span v-else>{{ scope.row.model }}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column align="center" label="类型" width="120px">
                                            <template slot-scope="scope">
                                                <el-form-item v-if="scope.row === editScope.editingRow" prop="type">
                                                    <el-select
                                                        v-model="scope.row.type"
                                                        @change="handleLowUnitBoxTypeChange($event, scope.row)"
                                                        style="width: 100%"
                                                    >
                                                        <el-option
                                                            v-for="item in lowUnitBoxTypes"
                                                            :key="item.value"
                                                            :label="item.name"
                                                            :value="item.value"
                                                        >
                                                        </el-option>
                                                    </el-select>
                                                </el-form-item>
                                                <span v-else>{{ getDictName(lowUnitBoxTypes, scope.row.type) }}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column align="center" label="进线柜容量(kVA)" width="100px">
                                            <template slot-scope="scope">
                                                <el-form-item
                                                    v-if="scope.row === editScope.editingRow && scope.row.type === '1'"
                                                    prop="capacity"
                                                >
                                                    <el-input-number
                                                        v-model="scope.row.capacity"
                                                        :precision="2"
                                                        :controls="false"
                                                        :min="0"
                                                        style="width: 100%"
                                                    ></el-input-number>
                                                </el-form-item>
                                                <span v-else>{{ scope.row.capacity }}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column align="center" label="计算电流(A)">
                                            <template slot-scope="scope">
                                                <el-form-item
                                                    v-if="scope.row === editScope.editingRow && scope.row.type === '2'"
                                                    prop="current"
                                                >
                                                    <el-input-number
                                                        v-model="scope.row.current"
                                                        :precision="2"
                                                        :controls="false"
                                                        :min="0"
                                                        style="width: 100%"
                                                    ></el-input-number>
                                                </el-form-item>
                                                <span v-else>{{ scope.row.current }}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column align="center" label="用途">
                                            <template slot-scope="scope">
                                                <el-form-item
                                                    v-if="
                                                        scope.row === editScope.editingRow &&
                                                        (scope.row.type === '1' || scope.row.type === '2')
                                                    "
                                                    prop="purpose"
                                                >
                                                    <el-input v-model="scope.row.purpose"></el-input>
                                                </el-form-item>
                                                <span v-else>{{ scope.row.purpose }}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column align="center" label="电容器容量(kvar)">
                                            <template slot-scope="scope">
                                                <el-form-item
                                                    v-if="scope.row === editScope.editingRow && scope.row.type === '3'"
                                                    prop="condenserCapacity"
                                                >
                                                    <el-input-number
                                                        v-model="scope.row.condenserCapacity"
                                                        :precision="2"
                                                        :controls="false"
                                                        :min="0"
                                                        style="width: 100%"
                                                    ></el-input-number>
                                                </el-form-item>
                                                <span v-else>{{ scope.row.condenserCapacity }}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column align="center" label="电压表规格上限(V)">
                                            <template slot-scope="scope">
                                                <el-form-item
                                                    v-if="scope.row === editScope.editingRow"
                                                    prop="voltmeterMax"
                                                >
                                                    <el-input-number
                                                        v-model="scope.row.voltmeterMax"
                                                        :precision="2"
                                                        :controls="false"
                                                        :min="0"
                                                        style="width: 100%"
                                                    ></el-input-number>
                                                </el-form-item>
                                                <span v-else>{{ scope.row.voltmeterMax }}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column align="center" label="电压表规格下限(V)">
                                            <template slot-scope="scope">
                                                <el-form-item
                                                    v-if="scope.row === editScope.editingRow"
                                                    prop="voltmeterMin"
                                                >
                                                    <el-input-number
                                                        v-model="scope.row.voltmeterMin"
                                                        :precision="2"
                                                        :controls="false"
                                                        :min="0"
                                                        style="width: 100%"
                                                    ></el-input-number>
                                                </el-form-item>
                                                <span v-else>{{ scope.row.voltmeterMin }}</span>
                                            </template>
                                        </el-table-column>

                                        <el-table-column align="center" label="电流表规格上限(A)">
                                            <template slot-scope="scope">
                                                <el-form-item
                                                    v-if="scope.row === editScope.editingRow"
                                                    prop="ammeterMax"
                                                >
                                                    <el-input-number
                                                        v-model="scope.row.ammeterMax"
                                                        :precision="2"
                                                        :controls="false"
                                                        :min="0"
                                                        style="width: 100%"
                                                    ></el-input-number>
                                                </el-form-item>
                                                <span v-else>{{ scope.row.ammeterMax }}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column align="center" label="电流表规格下限(A)">
                                            <template slot-scope="scope">
                                                <el-form-item
                                                    v-if="scope.row === editScope.editingRow"
                                                    prop="ammeterMin"
                                                >
                                                    <el-input-number
                                                        v-model="scope.row.ammeterMin"
                                                        :precision="2"
                                                        :controls="false"
                                                        :min="0"
                                                        style="width: 100%"
                                                    ></el-input-number>
                                                </el-form-item>
                                                <span v-else>{{ scope.row.ammeterMin }}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column align="center" label="备注" width="150">
                                            <template slot-scope="scope">
                                                <el-form-item v-if="scope.row === editScope.editingRow" prop="remark">
                                                    <el-input v-model="scope.row.remark"></el-input>
                                                </el-form-item>
                                                <span v-else>{{ scope.row.remark }}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            align="center"
                                            label="操作"
                                            type="action"
                                            width="100"
                                            fixed="right"
                                        >
                                            <template slot-scope="scope">
                                                <el-button
                                                    :data-delindex="scope"
                                                    @click="showLowUnitBoxEquipmentDialog(scope)"
                                                    type="text"
                                                    v-if="scope.row.id"
                                                    >柜体设备维护</el-button
                                                >
                                                <el-button
                                                    :data-delindex="scope"
                                                    @click="deleteLowUnitBox(scope)"
                                                    type="text"
                                                    v-if="editable"
                                                    >删除</el-button
                                                >
                                            </template>
                                        </el-table-column>
                                    </el-table-column>
                                </template>
                            </ex-edit-table-form>
                            <div class="ui-align--center ui-font--xxlarg" style="font-size: 24px" v-if="editable">
                                <i class="el-icon-circle-plus" title="添加柜体信息" @click="addLowUnitBox"></i>
                            </div>
                        </div>
                    </el-card>

                    <el-card class="box-card" style="margin: 0px 10px 10px 0px; width: 100%">
                        <div slot="header" class="notice_title">
                            <span>配网状态</span>
                        </div>
                        <div class="text row">
                            <el-table :data="formData.powerGridInfo.data" style="width: 100%">
                                <el-table-column prop="boxName" label="设备"> </el-table-column>
                                <el-table-column prop="wireName" label="连接线缆"> </el-table-column>
                                <el-table-column prop="joinEquipmentName" label="连接设备"> </el-table-column>
                                <el-table-column prop="address" label="线路状态"> </el-table-column>
                            </el-table>
                        </div>
                    </el-card>
                </el-col>
            </el-row>
        </el-form>

        <el-dialog
            width="80%"
            top="5vh"
            :title="dialog.title"
            v-if="dialog.visible"
            :visible.sync="dialog.visible"
            :modal-append-to-body="true"
            :close-on-click-modal="false"
            :append-to-body="true"
            :show-close="true"
        >
            <el-card class="box-card" style="margin: 0px 10px 10px 0px; width: 100%">
                <div class="text row">
                    <ex-edit-table-form
                        ref="editTableForm"
                        :rules="lowUnitBoxEquipmentRules"
                        size="mini"
                        :disabled="!editable"
                        :table-props="{
                            stripe: true,
                            border: true,
                            data: lowUnitBoxEquipments,
                            size: 'mini',
                            height: '300px',
                        }"
                        table-style="width: 100%"
                        class="task-book-info"
                        :rowSave="saveLowUnitBoxEquipment"
                    >
                        <template slot-scope="editScope">
                            <el-table-column align="center" label="类型" width="100px">
                                <template slot-scope="scope">
                                    <el-form-item v-if="scope.row === editScope.editingRow" prop="type">
                                        <el-select
                                            v-model="scope.row.type"
                                            @change="handleLowUnitBoxEquipmentTypeChange($event, scope.row)"
                                            style="width: 100%"
                                        >
                                            <el-option
                                                v-for="item in lowUnitBoxEquipmentTypes"
                                                :key="item.value"
                                                :label="item.name"
                                                :value="item.value"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                    <span v-else>{{ getDictName(lowUnitBoxEquipmentTypes, scope.row.type) }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="名称" width="100px">
                                <template slot-scope="scope">
                                    <el-form-item v-if="scope.row === editScope.editingRow" prop="name">
                                        <el-input v-model="scope.row.name"></el-input>
                                    </el-form-item>
                                    <span v-else>{{ scope.row.name }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="代号" width="140px">
                                <template slot-scope="scope">
                                    <el-form-item v-if="scope.row === editScope.editingRow" prop="code">
                                        <el-input v-model="scope.row.code"></el-input>
                                    </el-form-item>
                                    <span v-else>{{ scope.row.code }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="电流互感器规格">
                                <template slot-scope="scope">
                                    <el-form-item v-if="scope.row === editScope.editingRow" prop="currentTransformer">
                                        <el-input
                                            :disabled="scope.row.type !== '2'"
                                            v-model="scope.row.currentTransformer"
                                        ></el-input>
                                    </el-form-item>
                                    <span v-else>{{ scope.row.currentTransformer }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="断路器形式">
                                <template slot-scope="scope">
                                    <el-form-item v-if="scope.row === editScope.editingRow" prop="circuitBreakerForm">
                                        <el-input
                                            :disabled="scope.row.type !== '1'"
                                            v-model="scope.row.circuitBreakerForm"
                                        ></el-input>
                                    </el-form-item>
                                    <span v-else>{{ scope.row.circuitBreakerForm }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="断路器恒定值（A）">
                                <template slot-scope="scope">
                                    <el-form-item
                                        v-if="scope.row === editScope.editingRow"
                                        prop="circuitBreakerSteadyValue"
                                    >
                                        <el-input-number
                                            :disabled="scope.row.type !== '1'"
                                            v-model="scope.row.circuitBreakerSteadyValue"
                                            :precision="2"
                                            :controls="false"
                                            :min="0"
                                            style="width: 100%"
                                        ></el-input-number>
                                    </el-form-item>
                                    <span v-else>{{ scope.row.circuitBreakerSteadyValue }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="运行分析能力(kA)">
                                <template slot-scope="scope">
                                    <el-form-item
                                        v-if="scope.row === editScope.editingRow"
                                        prop="operationAnalyzeValue"
                                    >
                                        <el-input-number
                                            :disabled="scope.row.type !== '1'"
                                            v-model="scope.row.operationAnalyzeValue"
                                            :precision="0"
                                            :controls="false"
                                            :min="0"
                                            style="width: 100%"
                                        ></el-input-number>
                                    </el-form-item>
                                    <span v-else>{{ scope.row.operationAnalyzeValue }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="断路器脱扣器形式">
                                <template slot-scope="scope">
                                    <el-form-item
                                        v-if="scope.row === editScope.editingRow"
                                        prop="circuitBreakerTrippingForm"
                                    >
                                        <el-select
                                            :disabled="scope.row.type !== '1'"
                                            v-model="scope.row.circuitBreakerTrippingForm"
                                            clearable
                                            style="width: 100%"
                                        >
                                            <el-option
                                                v-for="item in circuitBreakerTrippingForms"
                                                :key="item.value"
                                                :label="item.name"
                                                :value="item.value"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                    <span v-else>{{
                                        getDictName(circuitBreakerTrippingForms, scope.row.circuitBreakerTrippingForm)
                                    }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="低压避雷器型号">
                                <template slot-scope="scope">
                                    <el-form-item
                                        v-if="scope.row === editScope.editingRow"
                                        prop="lowVoltageArresterType"
                                    >
                                        <el-input
                                            :disabled="scope.row.type !== '3'"
                                            v-model="scope.row.lowVoltageArresterType"
                                        ></el-input>
                                    </el-form-item>
                                    <span v-else>{{ scope.row.lowVoltageArresterType }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="低压避雷器电压上限(kV)">
                                <template slot-scope="scope">
                                    <el-form-item
                                        v-if="scope.row === editScope.editingRow"
                                        prop="lowVoltageArresterMax"
                                    >
                                        <el-input-number
                                            :disabled="scope.row.type !== '3'"
                                            v-model="scope.row.lowVoltageArresterMax"
                                            :precision="2"
                                            :controls="false"
                                            :min="0"
                                            style="width: 100%"
                                        ></el-input-number>
                                    </el-form-item>
                                    <span v-else>{{ scope.row.lowVoltageArresterMax }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="低压避雷器电压下限(kV)">
                                <template slot-scope="scope">
                                    <el-form-item
                                        v-if="scope.row === editScope.editingRow"
                                        prop="lowVoltageArresterMin"
                                    >
                                        <el-input-number
                                            :disabled="scope.row.type !== '3'"
                                            v-model="scope.row.lowVoltageArresterMin"
                                            :precision="2"
                                            :controls="false"
                                            :min="0"
                                            style="width: 100%"
                                        ></el-input-number>
                                    </el-form-item>
                                    <span v-else>{{ scope.row.lowVoltageArresterMin }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="熔断器规格(A)">
                                <template slot-scope="scope">
                                    <el-form-item v-if="scope.row === editScope.editingRow" prop="fuse">
                                        <el-input-number
                                            :disabled="scope.row.type !== '4'"
                                            v-model="scope.row.fuse"
                                            :precision="2"
                                            :controls="false"
                                            :min="0"
                                            style="width: 100%"
                                        ></el-input-number>
                                    </el-form-item>
                                    <span v-else>{{ scope.row.fuse }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="刀（熔）开关规格(A)">
                                <template slot-scope="scope">
                                    <el-form-item v-if="scope.row === editScope.editingRow" prop="knifeFuseSwitch">
                                        <el-input-number
                                            :disabled="scope.row.type !== '5'"
                                            v-model="scope.row.knifeFuseSwitch"
                                            :precision="2"
                                            :controls="false"
                                            :min="0"
                                            style="width: 100%"
                                        ></el-input-number>
                                    </el-form-item>
                                    <span v-else>{{ scope.row.knifeFuseSwitch }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                align="center"
                                label="操作"
                                type="action"
                                width="70"
                                fixed="right"
                                v-if="editable"
                            >
                                <template slot-scope="scope">
                                    <el-button
                                        :data-delindex="scope"
                                        @click="deleteLowUnitBoxEquipment(scope)"
                                        type="text"
                                        >删除</el-button
                                    >
                                </template>
                            </el-table-column>
                        </template>
                    </ex-edit-table-form>
                    <div class="ui-align--center ui-font--xxlarg" style="font-size: 24px" v-if="editable">
                        <i class="el-icon-circle-plus" title="添加柜体开关" @click="addLowUnitBoxEquipment"></i>
                    </div>
                </div>
            </el-card>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeModalHandle" size="mini">关闭</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
export default {
    name: 'SubstationForm',
    props: {
        id: {
            type: Number,
            default: null,
        },
        type: {
            type: String,
            default: 'add',
        },
    },
    data() {
        return {
            formData: {
                id: null,
                name: null,
                title: null,
                number: null,
                transformerType: null,
                manufacturer: null,
                contractor: null,
                model: null,
                propertyType: null,
                equipmentType: null,
                manufacturingNumber: null,
                productionDate: null,
                commissionDate: null,
                qualityAssuranceDate: null,
                capacity: null,
                highVoltage: null,
                current: null,
                lowVoltage: null,
                powerSupplyPoint: null,
                powerSupplyLine: null,
                longitude: null,
                latitude: null,
                customerId: null,
                transformerEntity: {
                    id: null,
                    substationId: null,
                    name: null,
                    model: null,
                    voltage: null,
                    voltageSpecification: null,
                    param1: null,
                    param2: null,
                },
                highUnitEntity: {
                    id: null,
                    substationId: null,
                    number: null,
                    name: null,
                    size: null,
                    model: null,
                    capacity: null,
                    current: null,
                },
                lowUnitEntity: {
                    id: null,
                    substationId: null,
                    number: null,
                    name: null,
                    size: null,
                },
                powerGridInfo: {
                    data: [],
                },
            },
            formRules: {
                name: [
                    {required: true, message: '请填写设备名称', trigger: 'blur'},
                    {min: 2, max: 40, message: '长度在 2 到 40 个字符', trigger: 'blur'},
                ],
                title: [
                    {required: true, message: '请填写设备简称', trigger: 'blur'},
                    {min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur'},
                ],
                number: [{required: true, message: '请选择', trigger: 'blur'}],
                transformerType: [{required: true, message: '请选择', trigger: 'blur'}],
                manufacturer: [
                    {required: false, message: '请选择', trigger: 'blur'},
                    {min: 2, max: 40, message: '长度在 2 到 40 个字符', trigger: 'blur'},
                ],
                contractor: [
                    {required: false, message: '请选择', trigger: 'blur'},
                    {min: 2, max: 40, message: '长度在 2 到 40 个字符', trigger: 'blur'},
                ],
                model: [{required: false, message: '请选择', trigger: 'blur'}],
                propertyType: [{required: true, message: '请选择资产性质', trigger: 'blur'}],
                manufacturingNumber: [
                    {required: false, message: '请选择', trigger: 'blur'},
                    {min: 2, max: 40, message: '长度在 2 到 40 个字符', trigger: 'blur'},
                ],
                productionDate: [{required: false, message: '请选择', trigger: 'blur'}],
                commissionDate: [{required: true, message: '请选择投产日期', trigger: 'blur'}],
                qualityAssuranceDate: [{required: false, message: '请选择', trigger: 'blur'}],
                capacity: [{required: true, message: '请填写容量', trigger: 'blur'}],
                highVoltage: [{required: true, message: '请填写高压侧电压', trigger: 'blur'}],
                current: [{required: true, message: '请填写计算电流', trigger: 'blur'}],
                lowVoltage: [{required: true, message: '请填写低压侧电压', trigger: 'blur'}],
                powerSupplyPoint: [{required: false, message: '请选择', trigger: 'blur'}],
                powerSupplyLine: [{required: false, message: '请选择', trigger: 'blur'}],
                longitude: [{required: false, message: '请选择', trigger: 'blur'}],
                latitude: [{required: false, message: '请选择', trigger: 'blur'}],
                transformerEntity: {
                    name: [
                        {required: true, message: '请填写名称', trigger: 'blur'},
                        {min: 2, max: 40, message: '长度在 2 到 40 个字符', trigger: 'blur'},
                    ],
                    model: [
                        {required: true, message: '请填写型号', trigger: 'blur'},
                        {min: 2, max: 40, message: '长度在 2 到 40 个字符', trigger: 'blur'},
                    ],
                    voltage: [{required: true, message: '请选择挡位', trigger: 'blur'}],
                    voltageSpecification: [{required: true, message: '请填写电压规格', trigger: 'blur'}],
                    param1: [
                        {required: false, message: '请填写重要参数1', trigger: 'blur'},
                        {min: 2, max: 40, message: '长度在 2 到 40 个字符', trigger: 'blur'},
                    ],
                    param2: [
                        {required: false, message: '请填写重要参数2', trigger: 'blur'},
                        {min: 2, max: 40, message: '长度在 2 到 40 个字符', trigger: 'blur'},
                    ],
                },
                highUnitEntity: {
                    number: [
                        {required: true, message: '请填写编号', trigger: 'blur'},
                        {min: 2, max: 40, message: '长度在 2 到 40 个字符', trigger: 'blur'},
                    ],
                    name: [
                        {required: true, message: '请填写名称', trigger: 'blur'},
                        {min: 2, max: 40, message: '长度在 2 到 40 个字符', trigger: 'blur'},
                    ],
                    size: [
                        {required: false, message: '请选择', trigger: 'blur'},
                        {min: 2, max: 40, message: '长度在 2 到 40 个字符', trigger: 'blur'},
                    ],
                    model: [
                        {required: true, message: '请填写型号', trigger: 'blur'},
                        {min: 2, max: 40, message: '长度在 2 到 40 个字符', trigger: 'blur'},
                    ],
                    capacity: [{required: true, message: '请填写容量', trigger: 'blur'}],
                    current: [{required: true, message: '请填写计算电流', trigger: 'blur'}],
                },
                lowUnitEntity: {
                    number: [{required: false, message: '请选择', trigger: 'blur'}],
                    name: [
                        {required: true, message: '请填写名称', trigger: 'blur'},
                        {min: 2, max: 40, message: '长度在 2 到 40 个字符', trigger: 'blur'},
                    ],
                    size: [
                        {required: false, message: '请选择', trigger: 'blur'},
                        {min: 2, max: 40, message: '长度在 2 到 40 个字符', trigger: 'blur'},
                    ],
                },
            },
            highUnitEquipments: [],
            highUnitEquipmentRules: {
                type: [{required: true, message: '请填写类型', trigger: 'blur'}],
                code: [
                    {required: true, message: '必填', trigger: 'blur'},
                    {min: 2, max: 40, message: '2到40个字', trigger: 'blur'},
                ],
                quantity: [{required: true, message: '必填', trigger: 'blur'}],
                loadSwitchMax: [
                    {
                        validator: (rule, value, callback) => {
                            let row = this.$refs.highUnitEquipmentTableForm.editingRow;
                            let isNull = value === undefined || value === null;
                            if (row && row.type && row.type === '1' && isNull) {
                                callback(new Error('必填'));
                            } else {
                                callback();
                            }
                        },
                        trigger: 'blur',
                    },
                ],
                loadSwitchMin: [
                    {
                        validator: (rule, value, callback) => {
                            let row = this.$refs.highUnitEquipmentTableForm.editingRow;
                            let isNull = value === undefined || value === null;
                            if (row && row.type && row.type === '1' && isNull) {
                                callback(new Error('必填'));
                            } else {
                                callback();
                            }
                        },
                        trigger: 'blur',
                    },
                ],
                shortCircuitFuse: [
                    {
                        validator: (rule, value, callback) => {
                            let row = this.$refs.highUnitEquipmentTableForm.editingRow;
                            let isNull = value === undefined || value === null;
                            if (row && row.type && row.type === '2' && isNull) {
                                callback(new Error('必填'));
                            } else {
                                callback();
                            }
                        },
                        trigger: 'blur',
                    },
                ],
                currentLimiterFuse: [
                    {
                        validator: (rule, value, callback) => {
                            let row = this.$refs.highUnitEquipmentTableForm.editingRow;
                            let isNull = value === undefined || value === null;
                            if (row && row.type && row.type === '3' && isNull) {
                                callback(new Error('必填'));
                            } else {
                                callback();
                            }
                        },
                        trigger: 'blur',
                    },
                ],
                elbowArrester: [
                    {
                        validator: (rule, value, callback) => {
                            value = typeof value === 'string' ? value.trim() : value;
                            let row = this.$refs.highUnitEquipmentTableForm.editingRow;
                            let isNull = value === undefined || value === null || value === '';
                            if (row && row.type && row.type === '4' && isNull) {
                                callback(new Error('必填'));
                            } else {
                                callback();
                            }
                        },
                        trigger: 'blur',
                    },
                ],
            },
            lowUnitBoxes: [],
            lowUnitBoxRules: {
                number: [
                    {required: true, message: '请填写编号', trigger: 'blur'},
                    {min: 2, max: 8, message: '长度在 2 到 8 个字符', trigger: 'blur'},
                ],
                model: [
                    {required: false, message: '必填', trigger: 'blur'},
                    {min: 2, max: 40, message: '长度在 2 到 40 个字符', trigger: 'blur'},
                ],
                type: [{required: true, message: '必填', trigger: 'blur'}],
                capacity: [{required: true, message: '必填', trigger: 'blur'}],
                current: [{required: true, message: '必填', trigger: 'blur'}],
                purpose: [
                    {required: false, message: '必填', trigger: 'blur'},
                    {min: 0, max: 50, message: '长度在 0 到 50 个字符', trigger: 'blur'},
                ],
                condenserCapacity: [{required: true, message: '必填', trigger: 'blur'}],
                voltmeterMax: [{required: true, message: '必填', trigger: 'blur'}],
                voltmeterMin: [{required: true, message: '必填', trigger: 'blur'}],
                ammeterMax: [{required: true, message: '必填', trigger: 'blur'}],
                ammeterMin: [{required: true, message: '必填', trigger: 'blur'}],
                remark: [
                    {required: false, message: '必填', trigger: 'blur'},
                    {min: 2, max: 500, message: '长度在 2 到 500 个字符', trigger: 'blur'},
                ],
            },
            dialog: {
                visible: false,
                title: '',
            },
            lowUnitBoxId: null,
            lowUnitBoxEquipmentRules: {
                type: [{required: true, message: '请选择类型', trigger: 'blur'}],
                name: [
                    {required: true, message: '请填写名称', trigger: 'blur'},
                    {min: 2, max: 40, message: '长度在 2 到 40 个字符', trigger: 'blur'},
                ],
                code: [
                    {required: true, message: '请填写代号', trigger: 'blur'},
                    {min: 2, max: 8, message: '长度在 2 到 8 个字符', trigger: 'blur'},
                ],
                currentTransformer: [{required: false, message: '请选择', trigger: 'blur'}],
                circuitBreakerForm: [{required: false, message: '请选择', trigger: 'blur'}],
                circuitBreakerSteadyValue: [{required: false, message: '请选择', trigger: 'blur'}],
                operationAnalyzeValue: [{required: false, message: '请选择', trigger: 'blur'}],
                circuitBreakerTrippingForm: [{required: false, message: '请选择', trigger: 'blur'}],
                lowVoltageArresterType: [
                    {required: false, message: '请选择', trigger: 'blur'},
                    {min: 2, max: 40, message: '长度在 2 到 40 个字符', trigger: 'blur'},
                ],
                lowVoltageArresterMax: [{required: false, message: '请选择', trigger: 'blur'}],
                lowVoltageArresterMin: [{required: false, message: '请选择', trigger: 'blur'}],
                fuse: [{required: false, message: '请选择', trigger: 'blur'}],
                knifeFuseSwitch: [{required: false, message: '请选择', trigger: 'blur'}],
            },
            lowUnitBoxEquipments: [],
            propertyTypes: [],
            highUnitEquipmentTypes: [],
            transformerVoltages: [],
            lowUnitBoxTypes: [],
            lowUnitBoxEquipmentTypes: [],
            circuitBreakerTrippingForms: [],
            transformerTypes: [],
            equipmentCustomers: [],
            equipmentTypes: [],
        };
    },
    computed: {
        editable() {
            return ['add', 'edit'].includes(this.type);
        },
    },
    methods: {
        uploadFileSucceed(res) {
            const {data} = res;
            this.formData.files.push(data);
        },
        //提交
        submitHandle(callback) {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    if (this.formData.id) {
                        this.$client.updateSubstation(this.formData.id, this.formData).then((ret) => {
                            if (ret.code === 0) {
                                this.$message({
                                    message: '操作成功！',
                                    type: 'success',
                                });
                                if (callback) {
                                    callback();
                                }
                            } else {
                                this.$message({
                                    message: ret.msg,
                                    type: 'warning',
                                });
                            }
                        });
                    } else {
                        this.$client.insertSubstation(this.formData).then((ret) => {
                            if (ret.code === 0) {
                                this.$message({
                                    message: '操作成功！',
                                    type: 'success',
                                });
                                if (callback) {
                                    callback();
                                }
                            } else {
                                this.$message({
                                    message: ret.msg,
                                    type: 'warning',
                                });
                            }
                        });
                    }
                }
            });
        },

        //添加高压侧开关柜设备
        addHighUnitEquipment() {
            if (this.formData.highUnitEntity.id) {
                this.highUnitEquipments.push({});
            } else {
                this.$message({
                    message: '请先保存变电站基本信息，再编辑柜体信息',
                    type: 'warning',
                });
            }
        },
        //保存高压侧开关柜设备
        saveHighUnitEquipment(row) {
            if (row.id) {
                // 更新高压侧开关柜设备
                this.$client.updateHighUnitEquipment(this.formData.highUnitEntity.id, row.id, row).then(() => {
                    this.getHighUnitEquipments();
                });
            } else {
                // 新增高压侧开关柜设备
                this.$client.insertHighUnitEquipment(this.formData.highUnitEntity.id, row).then(() => {
                    this.getHighUnitEquipments();
                });
            }
        },
        //删除高压侧开关柜设备
        deleteHighUnitEquipment({$index, row}) {
            if (row.id) {
                this.$confirm('确认删除？', '提示', {
                    confirmButtonClass: 'el-button el-button--mini el-button--primary',
                    type: 'warning',
                }).then(() => {
                    this.$client
                        .deleteHighUnitEquipment(this.formData.highUnitEntity.id, row.id)
                        .then(() => {
                            this.getHighUnitEquipments();
                        })
                        .catch((err) => {
                            this.$message.error(err.msg);
                            this.getHighUnitEquipments();
                        });
                });
            } else {
                this.highUnitEquipments.splice($index, 1);
            }
        },
        //获取高压侧开关柜设备
        getHighUnitEquipments() {
            if (this.formData.highUnitEntity.id) {
                this.$client.getHighUnitEquipments(this.formData.highUnitEntity.id).then((res) => {
                    this.highUnitEquipments = res.data;
                });
            }
        },
        //处理高压侧开关柜设备类型
        handleHighUnitEquipmentTypeChange(value, row) {
            /**
             *  设备类型 type
             *  负荷开关 1
             *      负荷开关上限(kV) loadSwitchMax
             *      负荷开关下限(A) loadSwitchMin
             *  短路容电器 2
             *      短路熔断器规格(A) shortCircuitFuse
             *  限流熔断器 3
             *      限流熔断器规格(A) currentLimiterFuse
             *  肘型避雷器 4
             *      肘型避雷器规格 elbowArrester
             */
            if (value === '1') {
                row.loadSwitchMax = 0;
                row.loadSwitchMin = 0;
                row.shortCircuitFuse = null;
                row.currentLimiterFuse = null;
                row.elbowArrester = null;
            } else if (value === '2') {
                row.loadSwitchMax = null;
                row.loadSwitchMin = null;
                row.shortCircuitFuse = 0;
                row.currentLimiterFuse = null;
                row.elbowArrester = null;
            } else if (value === '3') {
                row.loadSwitchMax = null;
                row.loadSwitchMin = null;
                row.shortCircuitFuse = null;
                row.currentLimiterFuse = 0;
                row.elbowArrester = null;
            } else if (value === '4') {
                row.loadSwitchMax = null;
                row.loadSwitchMin = null;
                row.shortCircuitFuse = null;
                row.currentLimiterFuse = null;
                row.elbowArrester = null;
            }
        },
        //添加低压侧户外开关站设备盒
        addLowUnitBox() {
            if (this.formData.lowUnitEntity.id) {
                this.lowUnitBoxes.push({});
            } else {
                this.$message({
                    message: '请先保存变电站基本信息，再编辑柜体信息',
                    type: 'warning',
                });
            }
        },
        //保存低压侧户外开关站设备盒
        saveLowUnitBox(row) {
            if (row.id) {
                // 更新高压侧开关柜设备
                this.$client.updateLowUnitBox(this.formData.lowUnitEntity.id, row.id, row).then(() => {
                    this.getLowUnitBoxes();
                });
            } else {
                // 新增高压侧开关柜设备
                this.$client.insertLowUnitBox(this.formData.lowUnitEntity.id, row).then(() => {
                    this.getLowUnitBoxes();
                });
            }
        },
        //删除低压侧户外开关站设备盒
        deleteLowUnitBox({$index, row}) {
            if (row.id) {
                this.$confirm('确认删除？', '提示', {
                    confirmButtonClass: 'el-button el-button--mini el-button--primary',
                    type: 'warning',
                }).then(() => {
                    this.$client
                        .deleteLowUnitBox(this.formData.lowUnitEntity.id, row.id)
                        .then(() => {
                            this.getLowUnitBoxes();
                        })
                        .catch((err) => {
                            this.$message.error(err.msg);
                            this.getLowUnitBoxes();
                        });
                });
            } else {
                this.lowUnitBoxes.splice($index, 1);
            }
        },
        //获取低压侧户外开关站设备盒
        getLowUnitBoxes() {
            this.$client.getLowUnitBoxes(this.formData.lowUnitEntity.id).then((res) => {
                this.lowUnitBoxes = res.data;
            });
        },
        // 处理低压侧户外开关站类型变换
        handleLowUnitBoxTypeChange(value, row) {
            /**
             *  设备类型 type
             *  进线柜 1
             *      设备容量(kVA) capacity 必填
             *  出线柜 2
             *      计算电流(A) current 必填
             *      柜体用途 purpose
             *  电容器柜 3
             *      设备容量(kvar) condenserCapacity 必填
             */
            if (value === '1') {
                row.capacity = 0;
                row.current = null;
                row.condenserCapacity = null;
            } else if (value === '2') {
                row.capacity = null;
                row.current = 0;
                row.condenserCapacity = null;
            } else if (value === '3') {
                row.capacity = null;
                row.current = null;
                row.purpose = '';
                row.condenserCapacity = 0;
            }
        },
        showLowUnitBoxEquipmentDialog({row}) {
            if (row && row.id) {
                this.lowUnitBoxId = row.id;
                this.dialog.visible = true;
                this.dialog.title = '柜体设备维护';
                this.getLowUnitBoxEquipments();
            }
        },

        //添加低压侧户外开关站设备
        addLowUnitBoxEquipment() {
            if (this.lowUnitBoxId) {
                this.lowUnitBoxEquipments.push({});
            } else {
                this.$message({
                    message: '请先选择低压侧户外开关站信息，再编辑设备信息',
                    type: 'warning',
                });
            }
        },
        //保存低压侧户外开关站设备
        saveLowUnitBoxEquipment(row) {
            if (row.id) {
                // 更新高压侧开关柜设备
                this.$client.updateLowUnitBoxEquipment(this.lowUnitBoxId, row.id, row).then(() => {
                    this.getLowUnitBoxEquipments();
                });
            } else {
                // 新增高压侧开关柜设备
                this.$client.insertLowUnitBoxEquipment(this.lowUnitBoxId, row).then(() => {
                    this.getLowUnitBoxEquipments();
                });
            }
        },
        //删除低压侧户外开关站设备
        deleteLowUnitBoxEquipment({$index, row}) {
            if (row.id) {
                this.$confirm('确认删除？', '提示', {
                    confirmButtonClass: 'el-button el-button--mini el-button--primary',
                    type: 'warning',
                }).then(() => {
                    this.$client
                        .deleteLowUnitBoxEquipment(this.lowUnitBoxId, row.id)
                        .then(() => {
                            this.getLowUnitBoxEquipments();
                        })
                        .catch((err) => {
                            this.$message.error(err.msg);
                            this.getLowUnitBoxEquipments();
                        });
                });
            } else {
                this.lowUnitBoxEquipments.splice($index, 1);
            }
        },
        //获取低压侧户外开关站设备
        getLowUnitBoxEquipments() {
            if (this.lowUnitBoxId) {
                this.$client.getLowUnitBoxEquipments(this.lowUnitBoxId).then((res) => {
                    this.lowUnitBoxEquipments = res.data;
                });
            } else {
                this.lowUnitBoxEquipments = [];
            }
        },
        handleLowUnitBoxEquipmentTypeChange(value, row) {
            if (value === '1') {
                row.currentTransformer = '';
                row.lowVoltageArresterType = '';
                row.lowVoltageArresterMax = null;
                row.lowVoltageArresterMin = null;
                row.fuse = null;
                row.knifeFuseSwitch = null;
            } else if (value === '2') {
                row.circuitBreakerForm = '';
                row.circuitBreakerSteadyValue = '';
                row.operationAnalyzeValue = null;
                row.circuitBreakerTrippingForm = null;
                row.lowVoltageArresterType = '';
                row.lowVoltageArresterMax = null;
                row.lowVoltageArresterMin = null;
                row.fuse = null;
                row.knifeFuseSwitch = null;
            } else if (value === '3') {
                row.currentTransformer = '';
                row.circuitBreakerForm = '';
                row.circuitBreakerSteadyValue = '';
                row.operationAnalyzeValue = null;
                row.circuitBreakerTrippingForm = null;
                row.fuse = null;
                row.knifeFuseSwitch = null;
            } else if (value === '4') {
                row.currentTransformer = '';
                row.circuitBreakerForm = '';
                row.circuitBreakerSteadyValue = '';
                row.operationAnalyzeValue = null;
                row.circuitBreakerTrippingForm = null;
                row.lowVoltageArresterType = '';
                row.lowVoltageArresterMax = null;
                row.lowVoltageArresterMin = null;
                row.knifeFuseSwitch = null;
            } else if (value === '5') {
                row.currentTransformer = '';
                row.circuitBreakerForm = '';
                row.circuitBreakerSteadyValue = '';
                row.operationAnalyzeValue = null;
                row.circuitBreakerTrippingForm = null;
                row.lowVoltageArresterType = '';
                row.lowVoltageArresterMax = null;
                row.lowVoltageArresterMin = null;
                row.fuse = null;
            }
        },

        closeModalHandle() {
            this.dialog.visible = false;
            this.lowUnitBoxEquipments = [];
        },
        getDictName(dictionaries, value) {
            if (!dictionaries || !value) {
                return '';
            }
            const dictionary = dictionaries.find((item) => item.value === value);
            if (dictionary) {
                return dictionary.name;
            }
            return '';
        },
    },
    created() {
        const dictMap = [
            {listName: 'propertyTypes', parentCode: 'device_nature_of_property_right'},
            {listName: 'highUnitEquipmentTypes', parentCode: 'substation_high_unit_equipment_type'},
            {listName: 'transformerVoltages', parentCode: 'substation_transformer_voltage'},
            {listName: 'lowUnitBoxTypes', parentCode: 'substation_low_unit_box_type'},
            {listName: 'lowUnitBoxEquipmentTypes', parentCode: 'substation_low_unit_box_equipment_type'},
            {listName: 'circuitBreakerTrippingForms', parentCode: 'substation_low_unit_box_equipment_breaker_form'},
            {listName: 'transformerTypes', parentCode: 'substation_transformer_type'},
            // {listName: 'equipmentTypes', parentCode: 'device_equipment_type'},
            {listName: 'equipmentTypes', parentCode: 'equipment_classification'},
        ];
        this.$client.listDictionaryChildrenArr(dictMap.map((it) => it.parentCode)).then((res) => {
            let {data: resData} = res;
            for (let key in resData) {
                let data = resData[key];
                let dict = dictMap.filter((it) => it.parentCode === key)[0];
                this[dict.listName] = data;
                console.log(key + '测试1');
                console.log(this[dict.listName] + '测试2');
            }
        });
        this.$client.listEquipmentCustomer().then((res) => {
            this.equipmentCustomers = res.data || {};
        });
        if (this.id) {
            this.$client.getSubstation(this.id).then((ret) => {
                if (ret.code === 0) {
                    const formData = ret.data || {};
                    if (!formData.transformerEntity) {
                        formData.transformerEntity = {};
                    }
                    if (!formData.highUnitEntity) {
                        formData.highUnitEntity = {};
                    }
                    if (!formData.lowUnitEntity) {
                        formData.lowUnitEntity = {};
                    }
                    if (!formData.powerGridInfo) {
                        formData.powerGridInfo = {
                            data: [],
                        };
                    }
                    this.formData = formData;
                    this.getHighUnitEquipments();
                    this.getLowUnitBoxes();
                } else {
                    this.$message({
                        message: ret.msg,
                        type: 'warning',
                    });
                }
            });
        }
    },
};
</script>
<style lang="less" scoped>
.el-icon-circle-plus {
    cursor: pointer;
}
</style>
