import {merge, cloneDeep} from 'lodash';
export function genItems(data, commonOption, specialOption = {}) {
    let items = cloneDeep(data);
    if (!Array.isArray(items)) {
        items = [items];
    }
    const values = {};
    const rules = {};
    const columns = [];
    items = items.map(it => {
        const item = merge(it, commonOption);
        item.options = merge(
            {
                style: {width: '100%'}
            },
            item.options
        );
        if (item.defaultValue) {
            values[item.prop] = item.defaultValue;
        }
        if (item.rules) {
            item.rules.forEach(rule => {
                if (rule.required && !rule.message) {
                    rule.message = '必填项不能为空';
                }
            });
            rules[item.prop] = item.rules;
        }
        columns.push({
            prop: item.prop,
            label: item.label,
            nonExit: item.nonExit,
            ...item.column
        });

        if (specialOption.forEditTable) {
            item.label = null;
        }
        delete item.column;
        return item;
    });
    return {items, values, rules, columns};
}
export function pickItemByProp(arr, propName) {
    return arr.filter(it => it.prop === propName)[0];
}
